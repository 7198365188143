import { BLOOM, CLOUDS, Constants, INTERFERENCES, PLANET_BGI, PLANET_BLOOM, PLANET_CLOUDS, PLANET_INTERFERENCES, PLANET_TRUE_COLOR, SATELLITES, TRUECOLOR, ZONES_BORDERS_COLOURS } from '../components/Constants';
import { square, buffer as mb_buffer, bbox, bboxPolygon /*centroid*/ } from '@turf/turf';
import _ from 'lodash';
import chroma from 'chroma-js';

export const getLayerId = (layerMode, imagery, is3D) => {
	if (!imagery) return layerMode;
	return layerMode === 'trueColour' ? `layer_${imagery.imageryId}` : layerMode === CLOUDS || layerMode === 'bgi' ? `layer_${layerMode}_${imagery.imageryId}` : `layer_${layerMode}${is3D ? '3D' : '2D'}_${imagery.imageryId}`;
};

export const getImagery = (state, imageryIdParam) => {
	if (!state || !imageryIdParam || !state.location || !state.location.imagery) return;
	return state.location.imagery.find((_imagery) => _imagery.imageryId === imageryIdParam);
};

export const getSatelliteParamName = (satellite) => {
	//return key name for imagery data imagery object for specific satellite
	// if (satellite === SATELLITES.copernicus) {
	// 	return 'copernicus';
	// } else if (satellite === PLANET_MENU_VALUE) {
	// 	return 'planet';
	// }
	return satellite;
};

export const arrangeS3Layers = (stateCopy, layerMode, layerId, imagery, selectedLayers) => {
	if (!stateCopy || !layerMode || !layerId || !imagery) return;
	let state = { ...stateCopy };
	if (layerMode === TRUECOLOR) {
		if (state.imageryLayers.length > 0) {
			let length = selectedLayers.length > 2 ? 2 : 1;
			for (let i = 0; i < length; i++) {
				state.map.moveLayer(layerId, state.imageryLayers[0].id);
			}
		}

		state.imageryLayers.unshift({
			id: layerId,
			layerMode: layerMode,
			// imageryId: state.images[imagery.date].imagery.imageryId,// restore this when we want to display meta data for copernicus again
			layer: state.map.getLayer(layerId),
		});
	} else if (layerMode === BLOOM && state.imageryLayers.length > 0) {
		if (state.imageryLayers[0].layerMode === TRUECOLOR) {
			state.imageryLayers.splice(1, 0, {
				id: layerId,
				layerMode: layerMode,
				// imageryId: state.images[imagery.date].imagery.imageryId, // restore this when we want to display meta data for copernicus again
				layer: state.map.getLayer(layerId),
			});

			if (state.imageryLayers[1] && state.imageryLayers[1].layerMode === BLOOM) {
				state.map.moveLayer(layerId, state.imageryLayers[1].id);
			} else {
				state.map.moveLayer(layerId, state.imageryLayers[state.imageryLayers.length - 1].id);
			}
		} else {
			//this case of 1 bloom layer
			state.imageryLayers.unshift({
				id: layerId,
				layerMode: layerMode,
				// imageryId: state.images[imagery.date].imagery.imageryId, // restore this when we want to display meta data for copernicus again
				layer: state.map.getLayer(layerId),
			});
			state.map.moveLayer(layerId, state.imageryLayers[1].id);
		}
	} else {
		state.imageryLayers.push({
			id: layerId,
			layerMode: layerMode,
			// imageryId: state.images[imagery.date].imagery.imageryId, // restore this when we want to display meta data for copernicus again
			layer: state.map.getLayer(layerId),
		});
		state.map.moveLayer(layerId, state.imageryLayers[state.imageryLayers.length - 1].id);
	}
	return state;
};

export const arrangePlanetLayers = (stateCopy, layerMode, layerId, imagery, selectedLayers) => {
	if (!stateCopy || !layerMode || !layerId || !imagery) return;
	let state = { ...stateCopy };
	if (layerMode === PLANET_TRUE_COLOR) {
		if (state.imageryLayers.length > 0) {
			let length = selectedLayers.length > 2 ? 2 : 1;
			for (let i = 0; i < length; i++) {
				state.map.moveLayer(layerId, state.imageryLayers[0].id);
			}
		}

		state.imageryLayers.unshift({
			id: layerId,
			layerMode: layerMode,
			imageryId: imagery.imageryId,
			layer: state.map.getLayer(layerId),
		});
	} else if (layerMode === PLANET_BLOOM && state.imageryLayers.length > 0) {
		if (state.imageryLayers[0].layerMode === PLANET_TRUE_COLOR) {
			state.imageryLayers.splice(1, 0, {
				id: layerId,
				layerMode: layerMode,
				imageryId: imagery.imageryId,
				layer: state.map.getLayer(layerId),
			});

			if (state.imageryLayers[1] && state.imageryLayers[1].layerMode === PLANET_BLOOM) {
				state.map.moveLayer(layerId, state.imageryLayers[1].id);
			} else {
				state.map.moveLayer(layerId, state.imageryLayers[state.imageryLayers.length - 1].id);
			}
		} else {
			//this case of 1 bloom layer
			state.imageryLayers.unshift({
				id: layerId,
				layerMode: layerMode,
				imageryId: imagery.imageryId,
				layer: state.map.getLayer(layerId),
			});
		}
	} else {
		let newPosition = state.imageryLayers.length - 1;
		if (state.imageryLayers.length === 3) {
			newPosition = 1;
		}
		state.imageryLayers.push({
			id: layerId,
			layerMode: layerMode,
			imageryId: imagery.imageryId,
			layer: state.map.getLayer(layerId),
		});
		if (_.includes(selectedLayers, PLANET_BLOOM) && state.imageryLayers[newPosition]) {
			state.map.moveLayer(layerId, state.imageryLayers[newPosition].id);
		}
	}
	return state;
};

export const arrangeLayers = (stateCopy, layerMode, layerId, imagery, selectedLayers, satelliteView) => {
	let state = { ...stateCopy };
	if (satelliteView === SATELLITES.copernicus) {
		state = arrangeS3Layers({ ...state }, layerMode, layerId, imagery, selectedLayers);
	} else if (satelliteView === SATELLITES.planet) {
		state = arrangePlanetLayers({ ...state }, layerMode, layerId, imagery, selectedLayers, satelliteView);
	}

	let location = state.location;
	let zone;
	if (location.saltWater && location.geometry?.properties?.zones?.length > 0) {
		// add zones if salt-water
		for (let i = 0; i < location.geometry.properties.zones.length; i++) {
			zone = location.geometry.properties.zones[i];

			const source = `${location.name}-zone-${i}-${zone.name}`;
			const source2 = `${location.name}-zone-2-${i}-${zone.name}`;
			if (state.map.getSource(source)) {
				//if source and layer exists remove them
				if (state.map.getLayer(zone.geometry.id)) {
					state.map.removeLayer(zone.geometry.id).removeSource(source);
				}
			}
			if (!state.map.getSource(source)) {
				state.map.addSource(source, {
					type: 'geojson',
					data: {
						type: 'Feature',
						geometry: zone.geometry?.geometry,
					},
				});

				state.map.addLayer({
					id: zone.geometry.id,
					type: 'line',
					source: source,
					layout: {},
					paint: {
						// 'line-color': ZONES_BORDERS_COLOURS[0],
						'line-color': '#000',
						'line-width': 4,
						// 'line-dasharray': [2, 1],
						'line-blur': 5,
						'line-opacity': 0.8,
					},
				});
			}
			const id = `${zone.geometry.id} ${zone.geometry.id}`;

			if (state.map.getSource(source2)) {
				//if source and layer exists remove them
				if (state.map.getLayer(id)) {
					state.map.removeLayer(id).removeSource(source2);
				}
			}
			if (!state.map.getSource(source2)) {
				state.map.addSource(source2, {
					type: 'geojson',
					data: {
						type: 'Feature',
						geometry: zone.geometry?.geometry,
					},
				});

				state.map.addLayer({
					id: id,
					type: 'line',
					source: source2,
					layout: {},
					paint: {
						// 'line-color': ZONES_BORDERS_COLOURS[0],
						'line-color': ZONES_BORDERS_COLOURS[0],
						'line-width': 1,
						// 'line-dasharray': [2, 1],
					},
				});
			}
		}
	}

	return state;
};
export const addSourceAndLayer = (stateCopy, layerId, features, layerType) => {
	let state = { ...stateCopy };

	if (!state.map.getSource(layerId)) {
		state.map
			.addSource(layerId, {
				type: 'geojson',
				data: {
					...{
						type: 'FeatureCollection',
						features: features,
					},
					features: features,
				},
			})
			.addLayer({
				id: layerId,
				type: layerType,
				source: layerId,
				layout: {},
			});
	}
	return state;
};

const getFeaturesArray = (state, imagery, layerMode, satelliteView) => {
	if (state.images && imagery.date && state.images[imagery.date].imagery && state.images[imagery.date].imagery[satelliteView].geoJSON && state.images[imagery.date].imagery[satelliteView].geoJSON.features) {
		return [...state.images[imagery.date].imagery[satelliteView].geoJSON.features].filter(
			(_feature) =>
				(layerMode === 'bloom' && _feature.properties.bloom > 0) ||
				(_feature.properties.bgi !== 0 /* 1 value for clouds */ && _.includes(['trueColour'], layerMode)) ||
				(_feature.properties.bgi === 1 && layerMode === CLOUDS) ||
				(_feature.properties.bgi !== 1 && _feature.properties.bgi !== 5 && layerMode === INTERFERENCES)
		);
	}

	return [];
};
//when we click on specific layer. - show copernicus meta data layers.
export const showLayer = async (appState, selectedLayers, imageryIdParam, layerMode, setSelectedLayers, createLayerHoverPopup, updateState, setPaintProperties, satelliteView) => {
	let state = { ...appState };
	let layer = selectedLayers.includes(layerMode) ? state.imageryLayers?.find((_layer) => _layer.imageryId === imageryIdParam && _layer.layerMode === layerMode) : undefined;

	if (selectedLayers.includes(layerMode)) {
		let filteredLayers = selectedLayers.filter((el) => el !== layerMode);
		setSelectedLayers(filteredLayers);

		if (!layer) return;
		if (state.map.getLayer(layer.id)) {
			state.map.removeLayer(layer.id).removeSource(layer.id);

			state.imageryLayers.splice(
				state.imageryLayers.findIndex((_layer) => _layer.id === layer.id),
				1
			);

			state.map.off('mousemove', layer.id);
			state.map.off('mouseout', layer.id);

			if (state.imageryLayers.some((_layer) => _.includes([BLOOM, CLOUDS, INTERFERENCES], _layer.layerMode))) {
				//original before starting with split layers
				state = createLayerHoverPopup(state.imageryLayers[state.imageryLayers.length - 1].id, state);
			}
		}

		await updateState(state, `showLayer ${layer} ${layerMode}`);
		return state;
	} else {
		setSelectedLayers([...selectedLayers, layerMode]);

		setTimeout(async () => {
			let imagery = getImagery(state, imageryIdParam);
			let layerId = getLayerId(layerMode, imagery, state.is3D);

			if (!imagery) return state;
			state.geoJSON = imagery[satelliteView].geoJSON;

			if (state.map && !state.map.getLayer(layerId)) {
				let layerType = layerMode === BLOOM && state.is3D ? 'fill-extrusion' : 'fill',
					latitudeDifference = Constants.SEN3_CELL_RADIUS / 2 / Constants.EARTH_CIRCUMFERENCE;

				let features = getFeaturesArray(state, imagery, layerMode, satelliteView);

				for (let featureIndex = 0; featureIndex < features.length; featureIndex++) {
					features[featureIndex] = mb_buffer(features[featureIndex], latitudeDifference, { units: 'degrees' });
					let boundingBox = bbox(features[featureIndex].geometry);

					features[featureIndex].geometry = bboxPolygon(square(boundingBox)).geometry;
				}

				state = addSourceAndLayer(state, layerId, features, layerType);

				state = setPaintProperties(layerId, layerType, layerMode, state);

				let blendedFeatures = features;

				if (features.length < 50000) {
					features.forEach((feature) => {
						const { row, column, color } = feature.properties;

						const adjacentFeatures = features.filter((adjacentFeature) => {
							const { adjacentRow, adjacentColumn } = adjacentFeature.properties;
							return (adjacentRow === row && Math.abs(adjacentColumn - column) === 1) || (adjacentColumn === column && Math.abs(adjacentRow - row) === 1);
						});

						let blendedColor = chroma(color);

						adjacentFeatures.forEach((adjacentFeature) => {
							const { color: adjacentColor } = adjacentFeature.properties;
							blendedColor = blendedColor.blend(chroma(adjacentColor), 0.5);
						});

						blendedFeatures.push({
							...feature,
							properties: {
								...feature.properties,
								color: blendedColor.hex(),
							},
						});
					});
				}

				state.map.getSource(layerId).setData({
					type: 'FeatureCollection',
					features: features,
				});
				state = arrangeLayers({ ...state }, layerMode, layerId, imagery, selectedLayers, satelliteView);

				if (layerMode === BLOOM || layerMode === CLOUDS || layerMode === INTERFERENCES) {
					state.layerPopup.active = false;
				}

				const index = state.imageryLayers.findIndex((object) => {
					return object.layerMode === layerMode;
				});

				if (!state.layerPopup.active && ((state.imageryLayers.length === 1 && !_.includes([TRUECOLOR, PLANET_TRUE_COLOR], state.imageryLayers[0].layerMode)) || state.imageryLayers.length > 1)) {
					state = createLayerHoverPopup(state.imageryLayers[index].id, state);
				}
				await updateState(state, 'createLayer');
			}
		}, 0);
	}
};

//when we click on specific layer. - show copernicus thumbnails, delete in the future when we have metadata.
export const showLayerThumbnails = async (appState, selectedLayers, imageryIdParam, layerMode, setSelectedLayers, createLayerHoverPopup, updateState, setPaintProperties, satelliteView) => {
	let state = { ...appState };
	let layer = selectedLayers.includes(layerMode) ? state.imageryLayers?.find((_layer) => _layer.layerMode === layerMode) : undefined;

	if (selectedLayers.includes(layerMode)) {
		let filteredLayers = selectedLayers.filter((el) => el !== layerMode);
		setSelectedLayers(filteredLayers);

		if (!layer) return;
		if (state.map.getLayer(layer.id)) {
			state.map.removeLayer(layer.id).removeSource(layer.id);

			state.imageryLayers.splice(
				state.imageryLayers.findIndex((_layer) => _layer.id === layer.id),
				1
			);
		}

		await updateState(state, `showLayer ${layer} ${layerMode}`);
		return state;
	} else {
		setSelectedLayers([...selectedLayers, layerMode]);

		let imagery = getImagery(state, imageryIdParam);
		let layerId = getLayerId(layerMode, imagery, state.is3D);

		if (!imagery) return state;
		state.geoJSON = imagery[satelliteView].geoJSON;

		if (layer) {
			setSelectedLayers(selectedLayers.filter((el) => el !== layerMode));
			// state = await removeLayer(planetLayer, state);
			if (state.map.getLayer(layer.id)) {
				state.map.removeLayer(layer.id).removeSource(layer.id);
			}
			state.imageryLayers = state.imageryLayers.filter((el) => el.layerMode !== layer.layerMode);
		}

		if (state.map && !state.map.getLayer(layerId)) {
			let imageryUrl = '';
			let selectedLayersCopy = [...selectedLayers];

			if (imagery) {
				if (layerMode === TRUECOLOR) {
					imageryUrl = imagery[satelliteView]?.urls?.trueColourThumbnail;
				} else if (layerMode === BLOOM) {
					//bloomThumbnail
					imageryUrl = imagery[satelliteView]?.urls?.bloomThumbnail;
				} else if (layerMode === INTERFERENCES) {
					//bgi thumbnail
					imageryUrl = imagery[satelliteView]?.urls?.bgiThumbnail;
				} else if (layerMode === CLOUDS) {
					//bgi thumbnail
					imageryUrl = imagery[satelliteView]?.urls?.cloudThumbnail;
				} else if (layerMode === INTERFERENCES) {
					//bgi thumbnail
					imageryUrl = imagery[satelliteView]?.urls?.interferencesThumbnail;
				}
			}

			if (imageryUrl && imageryUrl !== Constants.BLANK) {
				let boundingBox = bbox(state.location.geometry),
					imageCoordinates = [
						[boundingBox[0], boundingBox[3]],
						[boundingBox[2], boundingBox[3]],
						[boundingBox[2], boundingBox[1]],
						[boundingBox[0], boundingBox[1]],
					];

				//check if source exists before adding
				if (!state.map.getSource(layerId)) {
					await state.map.addSource(layerId, {
						type: 'image',
						url: imageryUrl,
						coordinates: imageCoordinates,
					});
				}

				if (state.map.getLayer(layerId)) {
					await state.map.removeLayer(layerId);
				}
				await state.map.addLayer({
					id: layerId,
					type: 'raster',
					source: layerId,
					paint: {
						'raster-fade-duration': 0,
					},
				});
				if (selectedLayersCopy.indexOf(layerMode) < 0) {
					selectedLayersCopy = [...selectedLayersCopy, layerMode];
				}

				state = arrangeLayers({ ...state }, layerMode, layerId, imagery, selectedLayersCopy, satelliteView);
			} else {
				if (selectedLayersCopy.indexOf(layerMode) >= 0) {
					selectedLayersCopy = selectedLayersCopy.filter((el) => el !== layerMode);
				} else {
					selectedLayersCopy = [...selectedLayersCopy, layerMode];
				}
			}

			await updateState(state, 'createLayer');
		}
	}
};

export const showPlanetService = async (imageryIdParam, appState, selectedLayers, setSelectedLayers, removeLayer, satelliteView, layer, imageryDate, updateState) => {
	//will be called to handle planet imageries
	let state = { ...appState },
		planetLayer = state.imageryLayers.find((_layer) => _layer.layerMode === layer);

	// state.location.imagery = state.planetImagery;
	let imagery = undefined;
	if (state.planetImagery) {
		imagery = state.planetImagery.find((_imagery) => _imagery.date === imageryDate);
	}

	if (!appState.location.saltWater) {
		if (!imagery) {
			if (_.includes(selectedLayers, layer)) {
				setSelectedLayers(selectedLayers.filter((el) => el !== layer));
			} else {
				setSelectedLayers([...selectedLayers, layer]);
			}
			return state;
		}
	} else {
		// in salt water case
		imagery = getImagery(state, imageryIdParam);
	}

	if (planetLayer) {
		setSelectedLayers(selectedLayers.filter((el) => el !== layer));
		// state = await removeLayer(planetLayer, state);
		if (state.map.getLayer(planetLayer.id)) {
			state.map.removeLayer(planetLayer.id).removeSource(planetLayer.id);
		}
		state.imageryLayers = state.imageryLayers.filter((el) => el.layerMode !== planetLayer.layerMode);
	} else {
		let boundingBox = bbox(state.location.geometry),
			imageCoordinates = [
				[boundingBox[0], boundingBox[3]],
				[boundingBox[2], boundingBox[3]],
				[boundingBox[2], boundingBox[1]],
				[boundingBox[0], boundingBox[1]],
			];

		let selectedLayersCopy = [...selectedLayers];

		let imageryUrl = '';
		if (!appState.location.saltWater) {
			if (imagery) {
				if (layer === PLANET_TRUE_COLOR) {
					imageryUrl = imagery[satelliteView]?.urls?.trueColourThumbnail;
				} else if (layer === PLANET_BLOOM) {
					//bloomThumbnail
					imageryUrl = imagery[satelliteView]?.urls?.bloomThumbnail;
				} else if (layer === PLANET_BGI) {
					//bgi thumbnail
					imageryUrl = imagery[satelliteView]?.urls?.bgiThumbnail;
				} else if (layer === PLANET_CLOUDS) {
					//bgi thumbnail
					imageryUrl = imagery[satelliteView]?.urls?.cloudThumbnail;
				} else if (layer === PLANET_INTERFERENCES) {
					//bgi thumbnail
					imageryUrl = imagery[satelliteView]?.urls?.interferencesThumbnail;
				}
			}
		} else {
			if (imagery) {
				if (layer === TRUECOLOR) {
					imageryUrl = imagery[satelliteView]?.urls?.trueColourThumbnail;
				} else if (layer === BLOOM) {
					//bloomThumbnail
					imageryUrl = imagery[satelliteView]?.urls?.chlThumbnail;
				} else if (layer === INTERFERENCES) {
					//bgi thumbnail
					imageryUrl = imagery[satelliteView]?.urls?.sedimentThumbnail;
				}
				// else if (layer === PLANET_CLOUDS) {
				// 	//bgi thumbnail
				// 	imageryUrl = imagery[satelliteView]?.urls?.bgiThumbnail;
				// }
			}
		}

		if (imageryUrl && imageryUrl !== Constants.BLANK) {
			if (state.map.getLayer(`planet${layer}`)) {
				await state.map.removeLayer(`planet${layer}`);
			}

			//check if source exists before adding
			if (!state.map.getSource(`planet${layer}`)) {
				await state.map.addSource(`planet${layer}`, {
					type: 'image',
					url: imageryUrl,
					coordinates: imageCoordinates,
				});
			}

			if (!state.map.getLayer(`planet${layer}`)) {
				await state.map.addLayer({
					id: `planet${layer}`,
					type: 'raster',
					source: `planet${layer}`,
					paint: {
						'raster-fade-duration': 0,
					},
				});
			}

			// state.imageryLayers.push({
			// 	id: `planet${layer}`,
			// 	layerMode: layer,
			// 	imageryId: imagery?.imageryId ? imagery.imageryId : state.images[state.imageryDate].imagery.imageryId,
			// 	layer: state.map.getLayer(`planet${layer}`),
			// });

			if (selectedLayersCopy.indexOf(layer) < 0) {
				selectedLayersCopy = [...selectedLayersCopy, layer];
			}

			state = arrangeLayers({ ...state }, layer, `planet${layer}`, imagery, selectedLayersCopy, satelliteView);
		} else {
			if (selectedLayersCopy.indexOf(layer) >= 0) {
				selectedLayersCopy = selectedLayersCopy.filter((el) => el !== layer);
			} else {
				selectedLayersCopy = [...selectedLayersCopy, layer];
			}
		}

		setSelectedLayers(selectedLayersCopy);
	}
	state = await updateState(state, `${layer}`);
	return state;
};

// TODO: review displayImageryService code and performance,
// export const displayImageryService = async (from, stateCopy, resetImagery, createImagery, createImage, resetDisplayedLocation, updateMapLocation, deleteLocation, setStartMonthFilter, showNewUI, getTrendData, updateState) => {
// 	let state = { ...stateCopy },
// 		itemsToDisplay = parseInt((window.innerWidth - state.swiperDecorationsLength) / state.swiperDivisor),
// 		request = {
// 			locationsId: state.location.locationsId,
// 			startDate: formatDate(state.location.startDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT),
// 			endDate: formatDate(state.location.endDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT),
// 		},
// 		firstAnalysesIndex = 0;

// 	let abortLoading = false;
// 	let loadingAborted = false;

// 	state.mode = Constants.MODES.View;

// 	let imagery = await fetch({
// 		url: `getImagery`,
// 		data: request,
// 	});

// 	if (!isBlank(imagery)) {
// 		// log(`displayImagery received ${imagery.length} items`);

// 		if (state.location.hasImagery) {
// 			state = resetImagery(false, state);
// 			state.lastCachedDate = undefined;
// 			state.images = {};
// 		}

// 		state.firstCachedDate = imagery[0].date;
// 		state.location.imagery = imagery;
// 		state.location.hasImagery = true;

// 		while (firstAnalysesIndex < state.location.imagery.length && !state.location.imagery[firstAnalysesIndex].analysesId && !state.location.imagery[firstAnalysesIndex].planetAnalysesId) {
// 			firstAnalysesIndex++;
// 		}

// 		if (firstAnalysesIndex !== state.location.imagery.length) {
// 			if (state.location.imagery[firstAnalysesIndex].analysesId) {
// 				state.location.imagery[firstAnalysesIndex].analyses = await fetch({
// 					url: `getAnalyses`,
// 					data: {
// 						analysesId: state.location.imagery[firstAnalysesIndex].analysesId,
// 					},
// 				});
// 			}
// 		}

// 		let validImageryItems = 0;

// 		for (let imageryIndex = 0; validImageryItems < itemsToDisplay + 1 && imageryIndex < state.location.imagery.length; imageryIndex++) {
// 			if (!abortLoading && !loadingAborted) {
// 				let nextIndex = imageryIndex + 1;

// 				if (imageryIndex === firstAnalysesIndex) {
// 					firstAnalysesIndex = 0;
// 				}

// 				if (firstAnalysesIndex === 0 && imageryIndex !== state.location.imagery.length - 1 && state.location.imagery[nextIndex].analysesId) {
// 					state.location.imagery[nextIndex].analyses = await fetch({
// 						url: `getAnalyses`,
// 						data: {
// 							analysesId: state.location.imagery[nextIndex].analysesId,
// 						},
// 					});
// 				}

// 				if (!isBlank(state.location.imagery[imageryIndex].analyses)) {
// 					state.location.imagery[imageryIndex].analyses = LZString.decompressFromBase64(state.location.imagery[imageryIndex].analyses.analyses)
// 						.split('!')
// 						.map((_prop) => _prop.split('/').map((__prop, _i) => (_i !== 3 ? Number(__prop) : __prop)));
// 					state.location.imagery[imageryIndex] = await createImagery(state.location.imagery[imageryIndex], state);
// 					state = await createImage(state.location.imagery[imageryIndex], null, state);
// 					state.lastCachedDate = state.location.imagery[imageryIndex].date;
// 					validImageryItems++;
// 				} else {
// 					// log('displayImagery::loop::noAnalyses', state.location.imagery[imageryIndex].date);
// 					state.location.imagery[imageryIndex].geoJSON = {
// 						type: 'FeatureCollection',
// 						features: [],
// 					};
// 					state.location.imagery[imageryIndex].status = 'missing';
// 					state = await createImage(state.location.imagery[imageryIndex], null, state);
// 					state.lastCachedDate = state.location.imagery[imageryIndex].date;
// 					validImageryItems++;
// 				}
// 			} else {
// 				// log('displayImagery::loop::cancelled', state.location.imagery[imageryIndex].date);
// 				break;
// 			}
// 		}

// 		if (!abortLoading && !loadingAborted) {
// 			// state.hasImageryDetails = state.hasImageryDetails;
// 			if (state.location.imagery && state.location.imagery[0] && state.location.imagery[itemsToDisplay - 1]) {
// 				state.firstDateDisplayed = state.location.imagery[0].date;
// 				state.lastDateDisplayed = state.location.imagery[itemsToDisplay - 1].date; //state.location.imagery[state.location.imagery.length - 1].date;
// 			}
// 		} else {
// 			state.images = [];
// 		}
// 	} else {
// 		let buttons = [
// 			{
// 				label: state.userCanNavigate && state.locations.length > 1 ? 'Close' : 'Retry',
// 				onClick: async () => {
// 					if (!state.location.hasImagery) {
// 						state = state.userCanNavigate && state.locations.length > 1 ? await resetDisplayedLocation(false) : await updateMapLocation('displayImageryDetails::noCachedData::retry');
// 					} else {
// 						state = await setStartMonthFilter(state.prevEndDate, true);
// 					}
// 				},
// 			},
// 		];

// 		if (!state.location.hasImagery && state.userIsAdmin) {
// 			buttons.push({
// 				label: 'Delete Location',
// 				onClick: async () => {
// 					state = await deleteLocation(state);
// 					// await updateState(state, 'displayImageryDetails::noCachedData::delete');
// 					resetDisplayedLocation(false);
// 				},
// 			});
// 		}
// 	}

// 	if (!abortLoading && !loadingAborted) {
// 		!showNewUI &&
// 			state.hudControl.update(
// 				`${state.location.name} | ${formatDate(state.location.dateRange[0].start_date, Constants.DATE_FORMATS.DATE_FORMAT)} to ${formatDate(state.location.dateRange[0].end_date, Constants.DATE_FORMATS.DATE_FORMAT)} | ${
// 					Number(state.location.polygonArea * Constants.UNITS[state.user.units][Constants.AREA_UNIT[state.user.units]].factor).toFixed(Constants.UNITS[state.user.units][Constants.AREA_UNIT[state.user.units]].decimals) || 'N/A'
// 				} ${Constants.UNITS[state.user.units][Constants.AREA_UNIT[state.user.units]].label}`
// 			);
// 		// state.imageryDate = state.imageryDate;

// 		if (state.lastCachedDate && !state.hasTrendDataForNavigator) {
// 			state = await getTrendData(state);
// 		}

// 		if (!abortLoading && !loadingAborted && state.location?.imagery?.length > itemsToDisplay) {
// 			if (state.doc.querySelector('.imageryRight')) {
// 				state.doc.querySelector('.imageryRight').classList.remove('hidden');
// 			}
// 		} else {
// 			loadingAborted = true;
// 			abortLoading = false;
// 		}
// 	} else {
// 		loadingAborted = true;
// 		abortLoading = false;
// 	}
// 	state = await updateState(state, 'displayImagery::abortLoading');

// 	return state;
// };

// export const displayImagerNew = async (stateCopy, resetImagery, abortLoading, loadingAborted, createImagery, createImage, appState, getTrendData, updateState, date, selectedDate, satelliteView) => {
// 	let state = { ...stateCopy },
// 		itemsToDisplay = parseInt((window.innerWidth - state.swiperDecorationsLength) / state.swiperDivisor),
// 		request = {
// 			locationsId: state.location.locationsId,
// 			// startDate: formatDate(state.location.startDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT),// original
// 			startDate: formatDate(startOfMonth(date), Constants.DATE_FORMATS.SHORT_DATE_FORMAT), //my change
// 			// endDate: formatDate(state.location.endDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT), // original
// 			endDate: formatDate(endOfMonth(date), Constants.DATE_FORMATS.SHORT_DATE_FORMAT), //my change
// 		},
// 		firstAnalysesIndex = 0;

// 	state.mode = Constants.MODES.View;

// 	let imagery = await fetch({
// 		url: `getImagery`,
// 		data: request,
// 	});

// 	if (!isBlank(imagery)) {
// 		if (state.location.hasImagery) {
// 			state = resetImagery(false, state);
// 			state.lastCachedDate = undefined;
// 			state.images = {};
// 		}

// 		state.firstCachedDate = imagery[0].date;
// 		state.location.imagery = imagery;
// 		state.location.hasImagery = true;

// 		while (firstAnalysesIndex < state.location.imagery.length && !state.location.imagery[firstAnalysesIndex].analysesId && !state.location.imagery[firstAnalysesIndex].planetAnalysesId) {
// 			firstAnalysesIndex++;
// 		}

// 		if (firstAnalysesIndex !== state.location.imagery.length) {
// 			if (state.location.imagery[firstAnalysesIndex].analysesId) {
// 				state.location.imagery[firstAnalysesIndex].analyses = await fetch({
// 					url: `getAnalyses`,
// 					data: {
// 						analysesId: state.location.imagery[firstAnalysesIndex].analysesId,
// 					},
// 				});
// 			}
// 		}

// 		let validImageryItems = 0;

// 		let counter = 0;
// 		let currentMonth;
// 		if (date) {
// 			currentMonth = getMonth(date);
// 		} else {
// 			let d = new Date();
// 			currentMonth = d.getMonth() + 1;
// 		}
// 		const dates = state.location.satellites[satelliteView.includes('Sentinel') ? 'S3' : 'PSD'].dates;

// 		for (let i = 0; i < 31 && dates && dates[dates.length - 1 - i]; i++) {
// 			let month = dates[dates.length - 1 - i].slice(4);
// 			month = month.slice(0, -2);
// 			if (month == currentMonth) {
// 				counter++;
// 			}
// 		}

// 		// itemsToDisplay = counter;
// 		itemsToDisplay = 31;

// 		for (let imageryIndex = 0; validImageryItems < itemsToDisplay + 1 && imageryIndex < state.location.imagery.length; imageryIndex++) {
// 			if (!abortLoading && !loadingAborted) {
// 				let nextIndex = imageryIndex + 1;

// 				if (imageryIndex === firstAnalysesIndex) {
// 					firstAnalysesIndex = 0;
// 				}

// 				if (firstAnalysesIndex === 0 && imageryIndex !== state.location.imagery.length - 1 && state.location.imagery[nextIndex].analysesId) {
// 					state.location.imagery[nextIndex].analyses = await fetch({
// 						url: `getAnalyses`,
// 						data: {
// 							analysesId: state.location.imagery[nextIndex].analysesId,
// 						},
// 					});
// 				}

// 				if (!isBlank(state.location.imagery[imageryIndex].analyses) || !isBlank(state.location.imagery[imageryIndex]?.planet?.analyses)) {
// 					if (!isBlank(state.location.imagery[imageryIndex].analyses)) {
// 						state.location.imagery[imageryIndex].analyses = LZString.decompressFromBase64(state.location.imagery[imageryIndex].analyses.analyses)
// 							.split('!')
// 							.map((_prop) => _prop.split('/').map((__prop, _i) => (_i !== 3 ? Number(__prop) : __prop)));
// 						state.location.imagery[imageryIndex] = await createImagery(state.location.imagery[imageryIndex], state);
// 					}

// 					state = await createImage(state.location.imagery[imageryIndex], null, state);
// 					state.lastCachedDate = state.location.imagery[imageryIndex].date;
// 					validImageryItems++;
// 				} else {
// 					state.location.imagery[imageryIndex].geoJSON = {
// 						type: 'FeatureCollection',
// 						features: [],
// 					};
// 					state.location.imagery[imageryIndex].status = 'missing';
// 					state = await createImage(state.location.imagery[imageryIndex], null, state);
// 					state.lastCachedDate = state.location.imagery[imageryIndex].date;
// 					validImageryItems++;
// 				}
// 			} else {
// 				break;
// 			}
// 		}

// 		if (!abortLoading && !loadingAborted) {
// 			state.hasImageryDetails = appState.hasImageryDetails;
// 			if (state.location.imagery && state.location.imagery[0] && state.location.imagery[itemsToDisplay - 1]) {
// 				state.firstDateDisplayed = state.location.imagery[0].date;
// 				state.lastDateDisplayed = state.location.imagery[itemsToDisplay - 1].date; //state.location.imagery[state.location.imagery.length - 1].date;
// 			}
// 		} else {
// 			state.images = [];
// 		}
// 	}
// 	state.imageryDate = appState.imageryDate;

// 	if (state.lastCachedDate && !state.hasTrendDataForNavigator) {
// 		state = await getTrendData(state);
// 	}

// 	state = await updateState(state, 'displayImagery::abortLoading');

// 	return state;
// };

export const backToCurrentLocation = async (appState, CheapRuler) => {
	let state = appState,
		location = state.location;

	let locationUpdated = false;

	const geometry = location.geometry.geometry,
		boundingBox = bbox(geometry),
		zoomstarted = async () => {
			let state = appState;
			state.map.off('zoomstart', zoomstarted);
			state.map.once('zoomend', mapReady);
		},
		mapReady = async () => {
			state.map.off('zoomend', mapReady);
			let centre = state.map.getCenter();

			if (!state.ruler.insideBBox(state.ruler.bufferPoint([centre.lng * 10000, centre.lat * 1000], 0.01), rulerBBox) && !locationUpdated) {
				state.map.once('zoomstart', async () => await zoomstarted());
				state.map.fitBounds(rulerBBox);
				if (!locationUpdated) {
					locationUpdated = true;
				}
			} else if (state.location.lastImagery) {
				state.map.setMaxBounds(!appState.userCanNavigate && appState?.restrictedAreas?.length === 1 ? state.map.getBounds() : undefined);
			}
			return state;
		};

	state.ruler = new CheapRuler(boundingBox[1]);
	let rulerBBox = state.ruler.bufferBBox(boundingBox, hanleZoomByPolygonArea(state));

	state = await mapReady();

	return state;
};

export const goToLocation = async (appState, updateState, displayImageryNew, CheapRuler, area, showNavigator, parseDate, setShowDefaultLayers, getTrendData, setShowNewTrends, setLoading, dateToLoad) => {
	let state = appState,
		location = state.location,
		isPreviousLocation = false;

	state.previousLocation = state.location;
	// setLoading(true);

	const geometry = location.geometry.geometry,
		boundingBox = bbox(geometry),
		zoomstarted = async () => {
			let state = appState;
			state.map.off('zoomstart', zoomstarted);
			state.map.once('zoomend', mapReady);
		},
		mapReady = async () => {
			state.map.off('zoomend', mapReady);
			let centre = state.map.getCenter();

			if (!state.ruler.insideBBox(state.ruler.bufferPoint([centre.lng, centre.lat], 0.1), rulerBBox)) {
				state.map.once('zoomstart', async () => await zoomstarted());
				state.map.fitBounds(rulerBBox);
			} else if (state.location.lastImagery) {
				// location.endDate = parseDate(location.lastImagery.toString(), Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date());
				state.location.endDate = new Date();
				state.location.startDate = parseDate(location.firstImagery.toString(), Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date());
				state.location = location;
				state.locations[state.locationIndex] = !isPreviousLocation ? location : state.locations[state.locationIndex];
				// state.coordinatesControl.updateCoordinates();
				state.analysis = 'trueColour';
				state.mode = Constants.MODES.View;
				state.imagery = undefined;
				state.imageryDate = undefined;

				state.map.setMaxBounds(!appState.userCanNavigate && appState?.restrictedAreas?.length === 1 ? state.map.getBounds() : undefined);
				state = await displayImageryNew('updateMapLocation::mapReady', state, dateToLoad, false, true);
				state = await getTrendData(state);
				if (state.trendDataForAllSatellites[SATELLITES.copernicus].length || state.trendDataForAllSatellites[SATELLITES.planet].length) {
					state.hasTrendDataForNavigator = true;
				}

				state = await updateState(state, 'updateMapLocation::mapReady');
			} else {
				await updateState(state, 'updateMapLocation::mapReady');
			}
			setShowNewTrends(true);

			setTimeout(() => {
				//when we show trends, re center map
				setLoading(false);
				state.map.resize();
				backToCurrentLocation(state, CheapRuler);
				setShowDefaultLayers(true);
			}, 0);

			return state;
		};

	state.lastRequestedLocationsId = !isPreviousLocation ? location.locationsId : state.lastRequestedLocationsId;
	state.locationIndex = state.locations.findIndex((_location) => _location.locationsId === state.location.locationsId);
	// set the top left latitude as the ruler constructor
	state.ruler = new CheapRuler(boundingBox[1]);
	// let rulerBBox = state.ruler.bufferBBox(boundingBox, 5);
	let rulerBBox = state.ruler.bufferBBox(boundingBox, hanleZoomByPolygonArea(state));

	state.imagery = undefined;
	state.images = undefined;

	// let polygon = state.location.geometry;
	state.location.polygonArea = Number(Number.parseFloat(area(state.location.geometry) / 1000000).toFixed(3));
	state.map.once('zoomstart', async () => await zoomstarted());

	state.method = 'updateMapLocation::fitBounds';
	state = await updateState(state, 'updateMapLocation::fitBounds');
	state.map.fitBounds(rulerBBox);
	showNavigator.current = true;

	return state;
};

const hanleZoomByPolygonArea = (state) => {
	if (state.location.polygonArea >= 188 && state.location.polygonArea <= 200) {
		return 10;
	}
	if (state.location.polygonArea >= 0 && state.location.polygonArea <= 10) {
		return 0.18;
	}
	return 5;
};

// const rotate360View = () => {
// 	context.rotate
// }

// const changeViewDimensions = () => {// 2D / 3D
// 	context.togglePitch
// }

// export const S3AOI = () => { //download S3 AOI
// 	onClick={() => window.open(context.imagery.urls.Subsetted300m_zip_url)}
// }

// export const rayLeigh = () => { //download rayLeigh
// if context.imagery.urls.Subsetted300m_zip_url
// 	onClick={() => window.open(context.imagery.urls.Rayleigh300m_zip_url)}
// }

// export const planetDownload = () => { //download planet
// if context.imagery.urls.planet_zip
// 	 onClick={() => window.open(context.imagery.urls.planet_zip)}
// }
