import React from 'react';
import { VictoryChart, VictoryAxis, LineSegment, VictoryVoronoiContainer } from 'victory';
import { victoryLGVTheme } from '../../../../victoryLGVTheme';
import { tickFormatService } from '../../../../../services/trendsServices';

export default function ViewWearther({ period, dateRange, weatherType, height = 200, victoryArea }) {
	const viewWeather = () => {
		let adjustment = 0;

		// const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi'),
		// containerComponent = <VictoryZoomVoronoiContainer responsive={true} zoomDimension="x" />,
		const containerComponent = <VictoryVoronoiContainer />,
			victoryAxis = (
				<VictoryAxis
					orientation="bottom"
					fixLabelOverlap={true}
					tickValues={period.current.map((_datum) => _datum?.date)}
					tickFormat={(datum) => tickFormatService(datum, dateRange)}
					style={{
						ticks: { stroke: 'black', size: 5 },
						tickLabels: { fill: 'black', fontSize: 15 },
					}}
					gridComponent={<LineSegment style={{ stroke: 'none' }} />}
				/>
			),
			victoryDependentAxis = (
				<VictoryAxis
					dependentAxis
					fixLabelOverlap={true}
					tickValues={period.averages[`${weatherType}DependentAxisLabels`]}
					tickFormat={(datum) => `${datum - adjustment} ${period.averages[`${weatherType}Annotation`]}`}
					style={{
						axis: { stroke: { fill: 'none' } },
						tickLabels: { fill: 'black', fontSize: 15 },
					}}
				/>
			),
			victoryChart = (
				<VictoryChart theme={victoryLGVTheme} preserveAspectRatio="none" width={600} height={height} domainPadding={10} padding={{ left: 70, right: 25, top: 30, bottom: 30 }} containerComponent={containerComponent}>
					{victoryDependentAxis}
					{victoryAxis}
					{victoryArea(weatherType, 'weather', period.current, weatherType === 'Temp' && period.min[weatherType] < 0 ? Math.ceil(Math.abs(period.min[weatherType])) + (10 - (Math.ceil(Math.abs(period.min[weatherType])) % 10)) : 0)}
				</VictoryChart>
			);

		if (weatherType === 'Temp' && period.min[weatherType] < 0) {
			adjustment = Math.ceil(Math.abs(period.min[weatherType])) + (10 - (Math.ceil(Math.abs(period.min[weatherType])) % 10));
			period.current.forEach((_current) => {
				_current.Temp += adjustment;
			});

			period.averages[weatherType] += adjustment;
			period.min[weatherType] += adjustment;
			period.max[weatherType] += adjustment;
		}

		return <React.Fragment key={weatherType}>{victoryChart}</React.Fragment>;
	};

	return <>{viewWeather()}</>;
}
