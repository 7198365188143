import React from 'react';
import './index.css';
import EmptyState from '../../../../shared/icons/activities/emptyState.png';
import ActivitiesList from './activitiesList/ActivitiesList';

export default function Activities({ activitiesList, user, selectedAlertDate, editActivity, userMode, deleteActivity }) {
	let content = '';
	if (!activitiesList || activitiesList?.length === 0) {
		const title = 'No Activities yet';
		//empty state
		content = (
			<div className="empty-state display-flex display-flex-column">
				<img height={'24px'} width={'24px'} src={EmptyState} alt="empty-list" />
				<span className="empty-state-title">{title}</span>
			</div>
		);
	} else {
		// activities list
		content = <ActivitiesList activitiesList={activitiesList} user={user} selectedAlertDate={selectedAlertDate} editActivity={editActivity} userMode={userMode} deleteActivity={deleteActivity} />;
	}
	return <div className="activities-container">{content}</div>;
}
