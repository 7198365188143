import React, { useEffect, useRef } from 'react';
import './index.css';
import { ACTIVITIES_TABLE_COLUMNS, Constants } from '../../../../Constants';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { format } from 'date-fns';
import ActivitiesMenu from '../../activitiesMenu/ActivitiesMenu';

export default function ActivitiesList({ activitiesList, user, selectedAlertDate, editActivity, userMode, deleteActivity }) {
	const unit = Constants.UNITS[user.units].Prescription.label;
	const scrollDone = useRef(false);
	let scrollToElementId = useRef();
	const refs = activitiesList.reduce((acc, value) => {
		acc[value.activitiesId] = React.createRef();
		return acc;
	}, {});

	const handleScroll = (id) =>
		refs[id].current.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});

	let selectedActivityIndex = useRef();
	const formattedDate = selectedAlertDate ? format(selectedAlertDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT) : '';
	selectedActivityIndex.current = activitiesList.findIndex((el) => format(new Date(el.startTime), Constants.DATE_FORMATS.SHORT_DATE_FORMAT) === formattedDate);
	useEffect(() => {
		if (selectedAlertDate) {
			scrollToElementId.current = selectedActivityIndex.current >= 0 ? activitiesList[selectedActivityIndex.current].activitiesId : '';
			if (selectedActivityIndex.current >= 0) {
				if (!scrollDone.current) {
					//scroll code here
					handleScroll(scrollToElementId.current);
					//reset scrolling
					scrollDone.current = true;
				}
			}
		}
	});

	return (
		<div className="activities-list-container">
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								{ACTIVITIES_TABLE_COLUMNS.map((el, index) => (
									<TableCell key={index}>{el}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{activitiesList.map((activity, index) => (
								<TableRow key={index} ref={refs[activity.activitiesId]} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className={index === selectedActivityIndex.current ? 'selected-activity' : ''}>
									<TableCell align="right">{new Date(activity.startTime).toLocaleDateString()}</TableCell>
									<TableCell align="right">{activity.zone}</TableCell>
									<TableCell align="right">{activity.metadata?.formula}</TableCell>
									<TableCell align="right">
										{activity.metadata?.dosage * Constants.UNITS[user.units].Prescription.factor} {unit}
									</TableCell>
									<TableCell align="right">
										<ActivitiesMenu
											editActivity={() => editActivity(activity)}
											disabled={userMode !== 'expert'}
											deleteActivity={() => {
												deleteActivity(activity);
											}}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
}
