import React, { useEffect, useState } from 'react';
import './index.css';
import { Alert } from '@mui/material';
import { CONTACT_EXPERT_URL, Constants, TREATMENT_SEVERITY_STATUS_HIGH, TREATMENT_SEVERITY_STATUS_LOW, TREATMENT_SEVERITY_STATUS_MED, TREATMENT_SEVERITY_STATUS_NORMAL, TREATMENT_SEVERITY_STATUS_NO_SEVERITY, TREATMENT_SEVERITY_STATUS_SEVERE, TRENDS_ACTIVITIES_TAB } from '../../../Constants';

import severe from '../../../../shared/icons/prescriptionIcons/severe.png';
import high from '../../../../shared/icons/prescriptionIcons/high.png';
import moderate from '../../../../shared/icons/prescriptionIcons/moderate.png';
import low from '../../../../shared/icons/prescriptionIcons/low.png';
import normal from '../../../../shared/icons/prescriptionIcons/normal.png';
import noSeverity from '../../../../shared/icons/prescriptionIcons/noDataSeverity.png';

import { fetch } from '../../../Services';
import { getFormulasToDisplay } from '../../../../services/treatmentsServices';

import TreatmentStatus from './treatmentStatus/TreatmentStatus';
import TreatmentInfo from './treatmentInfo/TreatmentInfo';

export default function Treatment({ severity = TREATMENT_SEVERITY_STATUS_NO_SEVERITY, user, dosageOxy, dosageBlu, lastTreatmentDate, locationsId, showReportTreatment, setShowReportTreatment, selectedTab, setSelectedTab, formulas, bpi, past4DaysBpi }) {
	const [showNotification, setShowNotification] = useState(false);
	const [notificationType, setNotificationType] = useState('error');
	const [notificationMessage, setNotificationMessage] = useState('');
	const [formulasToDisplay, setFormulasToDisplay] = useState('');
	const [showDosages, setShowDosages] = useState('');
	const compositeBloomTitle = 'Composite bloom:';
	let imgSrc = '';
	let mainTitle = 'Treatment recommended';
	let description = '';
	const unit = Constants.UNITS[user.units].Prescription.label;
	const bloomUnit = Constants.UNITS[user.units].BloomLevel.label;
	const contactButtonText = 'Contact expert';
	const reportTreatmentButtonText = 'Report treatment';
	const lastTreatment = lastTreatmentDate ? `Last treatment ${lastTreatmentDate}` : '';
	let imgWidth = '84px';
	let imgHeight = '90px';

	useEffect(() => {
		if (!formulasToDisplay && formulas && formulas.length) {
			const formaulasCopy = getFormulasToDisplay(formulas, [dosageOxy, dosageBlu]);
			setFormulasToDisplay(formaulasCopy);
		}
	}, [formulasToDisplay, setFormulasToDisplay, formulas, dosageOxy, dosageBlu]);

	switch (severity) {
		case TREATMENT_SEVERITY_STATUS_NORMAL:
			imgSrc = normal;
			mainTitle = 'No bloom detected';
			description = undefined;
			imgWidth = '45px';
			imgHeight = '45px';
			if (showDosages) {
				setShowDosages(false);
			}
			break;

		case TREATMENT_SEVERITY_STATUS_LOW:
			imgSrc = low;
			mainTitle = `${compositeBloomTitle} Low`;
			description = 'We recommend treating with: ';
			if (!showDosages) {
				setShowDosages(true);
			}

			break;
		case TREATMENT_SEVERITY_STATUS_MED:
			imgSrc = moderate;
			mainTitle = `${compositeBloomTitle} Moderate`;
			description = 'We recommend treating with: ';
			if (!showDosages) {
				setShowDosages(true);
			}

			break;

		case TREATMENT_SEVERITY_STATUS_HIGH:
			imgSrc = high;
			mainTitle = `${compositeBloomTitle} High`;
			description = 'We recommend treating with: ';
			if (!showDosages) {
				setShowDosages(true);
			}

			break;

		case TREATMENT_SEVERITY_STATUS_SEVERE:
			imgSrc = severe;
			mainTitle = `${compositeBloomTitle} Critical`;
			description = 'We recommend treating with: ';
			if (!showDosages) {
				setShowDosages(true);
			}

			break;
		case TREATMENT_SEVERITY_STATUS_NO_SEVERITY:
			imgSrc = noSeverity;
			mainTitle = 'Unknown bloom status.';
			description = 'Limited satellite availability';
			imgWidth = '45px';
			imgHeight = '45px';
			if (showDosages) {
				setShowDosages(false);
			}
			break;
		default:
	}

	const contactButtonClicked = () => {
		const dosages = [`${dosageOxy} ${unit}`, `${dosageBlu} ${unit}`];
		let data = { locationsId: locationsId };
		if (dosageBlu && dosageOxy) {
			data = { ...data, treatmentType: formulas, dosage: dosages };
		}

		fetch({ url: CONTACT_EXPERT_URL, data: data })
			.then((response) => {
				if (response && response.consultAnExpert) {
					setNotificationType('success');
					setNotificationMessage(response.consultAnExpert);
				} else if (response && response.Error) {
					setNotificationType('error');
					setNotificationMessage(response.Error);
				}
				setShowNotification(true);
				setTimeout(() => {
					setShowNotification(false);
				}, 6000);
			})
			.catch((e) => {});
	};

	const reportTreatmentCB = () => {
		if (selectedTab !== TRENDS_ACTIVITIES_TAB) {
			setSelectedTab(TRENDS_ACTIVITIES_TAB);
		}
		if (!showReportTreatment) {
			setShowReportTreatment(true);
		}
	};

	const tooltipTitle = (
		<div
			style={{
				width: '200px',
				fontSize: '14px',
				fontWeight: 400,
				padding: '7px 10px',
			}}>
			{'Composite bloom is calculated average over the last 15 days'}
		</div>
	);

	let arrowIconStyle = { color: 'rgba(0,0,0,0.5)', height: '12px', width: '12px' };
	let bpiResult = parseFloat(((bpi - past4DaysBpi) / past4DaysBpi) * 100);
	if (bpiResult < 0) {
		arrowIconStyle = { ...arrowIconStyle, transform: 'rotate(180deg)' };
		bpiResult = Math.abs(bpiResult);
		bpiResult = Math.round(bpiResult).toFixed(Constants.UNITS[user.units].Bpi.decimals);
	}

	const treatmentStatusProps = {
		imgHeight,
		imgSrc,
		imgWidth,
		bpi,
		bloomUnit,
		arrowIconStyle,
		bpiResult,
		user,
		tooltipTitle,
		showCompositeBloom: severity !== TREATMENT_SEVERITY_STATUS_NO_SEVERITY && severity !== TREATMENT_SEVERITY_STATUS_NORMAL ? true : false,
	};

	const treatmentInfoProps = {
		mainTitle,
		description,
		formulasToDisplay,
		contactButtonClicked,
		contactButtonText,
		reportTreatmentCB,
		reportTreatmentButtonText,
		lastTreatmentDate,
		lastTreatment,
	};

	return (
		<div className="treatment-container display-flex display-flex-column" style={showNotification ? { minHeight: '240px' } : {}}>
			<div className="display-flex display-flex-row">
				<TreatmentStatus {...treatmentStatusProps} />

				<TreatmentInfo {...treatmentInfoProps} />
			</div>
			{showNotification ? (
				<Alert style={{ marginTop: '10px' }} severity={notificationType}>
					{notificationMessage}
				</Alert>
			) : (
				''
			)}
		</div>
	);
}
