import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InfoIcon from '@mui/icons-material/Info';
import { Constants } from '../../../../Constants';
import { Tooltip } from '@mui/material';

export default function TreatmentStatus({ imgHeight, imgSrc, imgWidth, bpi, bloomUnit, arrowIconStyle, bpiResult, user, tooltipTitle, showCompositeBloom }) {
	let result = '';
	let bpiFixed = '';
	try {
		result = parseFloat(bpiResult)?.toFixed(Constants.UNITS[user.units].BloomLevel.decimals);
		bpiFixed = parseFloat(bpi)?.toFixed(Constants.UNITS[user.units].BloomLevel.decimals);
	} catch (e) {
		result = bpiResult;
		bpiFixed = bpi;
	}

	return (
		<div style={{ height: '84px', position: ' relative', width: showCompositeBloom ? '109px' : 'auto' }}>
			<img src={imgSrc} width={imgWidth} height={imgHeight} alt="prescription severity icon" />
			{showCompositeBloom ? <span style={{ width: '84px', height: '15px', top: '69px', background: '#f7f9fe', position: 'absolute', left: '0' }}></span> : ''}

			{showCompositeBloom ? (
				<div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: '84px', alignItems: 'center', width: '109px' }}>
					<span className="composite-bloom"> {`${bpiFixed} ${bloomUnit}`} </span>
					<Tooltip
						title={tooltipTitle}
						placement="top"
						className="tooltip-font"
						slotProps={{
							tooltip: {
								sx: {
									color: 'rgba(0,0,0,0.75)',
									backgroundColor: '#ffff',
								},
							},
						}}>
						<InfoIcon fontSize="small" style={{ color: 'rgba(0,0,0,0.5)', height: '12px', width: '12px' }} className="info-icon-hover" />
					</Tooltip>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						{/* {bpiResult !== 0 ? <ArrowUpwardIcon fontSize="small" style={arrowIconStyle} /> : ''}
						{bpiResult !== 0 ? <span style={{ margin: '0 2px', fontSize: '12px', fontFamily: 'Inter', fontWeight: '400' }}> {`${result}${Constants.UNITS[user.units].Bpi.label}`}</span> : ''} */}
					</div>
				</div>
			) : (
				''
			)}
		</div>
	);
}
