import React, { useEffect, useRef } from 'react';
import './index.css';
import LayersIcon from '@mui/icons-material/Layers';
import _ from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, ListItemIcon, MenuList, Paper } from '@mui/material';
import {
  BLOOM,
  CLOUDS,
  INTERFERENCES,
  PLANET_BLOOM,
  PLANET_CLOUDS,
  PLANET_TRUE_COLOR,
  PLANET_INTERFERENCES,
  PRO_USER_LAYERS_MENU,
  SATELLITES,
  STANDARD_USER_LAYERS_MENU,
  TRUECOLOR,
} from '../Constants';

export default function LayerManagerComponent({
  selectedLocation,
  setSelectedLayers,
  selectedLayers = [],
  showLayerNew,
  satelliteView,
  layerIconDisabled,
  isProUser,
  isTablet,
  isPortrait,
}) {
  const iconStyle = isTablet
    ? { height: '22px', width: '22px', opacity: '1' }
    : { height: '16px', width: '16px', opacity: '1' };
  const layersMenuTitle = 'Layers:';

  const [openMenu, setOpenMenu] = React.useState(false);
  const [elementClciked, setElementClciked] = React.useState(false);

  const handleClick = () => {
    if (!selectedLocation.name) return '';
    setOpenMenu(!openMenu);
    setElementClciked(true);
  };

  const wrapperRef = useRef(null);

  const handleItemClick = (element) => {
    if (satelliteView === SATELLITES.planet) {
      if (element.value === TRUECOLOR) {
        if (_.includes(selectedLayers, PLANET_TRUE_COLOR)) {
          showLayerNew(PLANET_TRUE_COLOR);
        } else {
          setSelectedLayers([...selectedLayers, PLANET_TRUE_COLOR]);
          showLayerNew(PLANET_TRUE_COLOR);
        }
      } else if (element.value === BLOOM) {
        if (_.includes(selectedLayers, PLANET_BLOOM)) {
        } else {
          setSelectedLayers([...selectedLayers, PLANET_BLOOM]);
        }
        showLayerNew(PLANET_BLOOM);
      } else if (element.value === INTERFERENCES) {
        if (_.includes(selectedLayers, PLANET_INTERFERENCES)) {
        } else {
          setSelectedLayers([...selectedLayers, PLANET_INTERFERENCES]);
        }
        showLayerNew(PLANET_INTERFERENCES);
      } else if (element.value === CLOUDS) {
        if (_.includes(selectedLayers, PLANET_CLOUDS)) {
        } else {
          setSelectedLayers([...selectedLayers, PLANET_CLOUDS]);
        }
        showLayerNew(PLANET_CLOUDS);
      }
    } else {
      showLayerNew(element.value);
    }
  };
  let layers = isProUser ? PRO_USER_LAYERS_MENU : STANDARD_USER_LAYERS_MENU;
  if (selectedLocation?.saltWater) {
    //filter clouds layer
    layers = layers.filter((el) => el.value !== CLOUDS);
  }

  const isCheckboxChecked = (element) => {
    let value = '';
    if (satelliteView === SATELLITES.planet && element.value === TRUECOLOR) {
      value = selectedLayers.find((data) => data === PLANET_TRUE_COLOR);
    } else if (satelliteView === SATELLITES.planet && element.value === BLOOM) {
      value = selectedLayers.find((data) => data === PLANET_BLOOM);
    } else if (
      satelliteView === SATELLITES.planet &&
      element.value === INTERFERENCES
    ) {
      value = selectedLayers.find((data) => data === PLANET_INTERFERENCES);
    } else if (
      satelliteView === SATELLITES.planet &&
      element.value === CLOUDS
    ) {
      value = selectedLayers.find((data) => data === PLANET_CLOUDS);
    } else {
      value = selectedLayers.find((data) => element.value === data);
    }
    return value ? true : false;
  };

  const isCheckBoxDisabled = (element) => {
    return false;
  };

  let containerStyle = {
    borderTopRightRadius: openMenu ? '0px' : '20px',
    borderBottomRightRadius: openMenu ? '0px' : '20px',
  };

  const close = () => {
    if (elementClciked && openMenu) {
      setOpenMenu(!openMenu);
      setElementClciked(false);
    }
  };

  useOnClickOutside(wrapperRef, close);
  if (isTablet) {
    containerStyle = {
      ...containerStyle,
      height: '37px',
      width: '37px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: isPortrait ? '183px' : '115px',
    };
  }

  return (
    <div
      className={'cursor-pointer layer-manager-container'}
      style={containerStyle}
      ref={wrapperRef}
    >
      <LayersIcon
        disabled={layerIconDisabled ? false : true}
        className='menu-button'
        onClick={handleClick}
        style={iconStyle}
      />
      {openMenu && layerIconDisabled && (
        <div className={`fill-space ${isTablet ? 'tablet' : ''}`}></div>
      )}
      {openMenu && layerIconDisabled && (
        <Paper
          sx={{ width: 320, maxWidth: '100%' }}
          style={
            isTablet
              ? {
                  borderRadius: '22px',
                  borderTopLeftRadius: '0px',
                  paddingBottom: '0px',
                }
              : { borderRadius: '22px', borderTopLeftRadius: '0px' }
          }
          className='menu-container'
        >
          <MenuList>
            <MenuItem
              key={'05'}
              className={`menu-item ${isTablet ? 'tablet ' : ''} padding-top-0`}
              style={isTablet ? { marginLeft: '13px' } : {}}
            >
              {layersMenuTitle}
            </MenuItem>

            {layers.map((el, index) => (
              <MenuItem
                className={`menu-item ${isTablet ? 'tablet ' : ''} ${
                  isCheckboxChecked(el) ? 'checked' : ''
                }`}
                key={index}
                disabled={isCheckBoxDisabled(el)}
                onClick={(e) => {
                  handleItemClick(el);
                }}
              >
                {' '}
                <ListItemIcon
                  style={
                    isTablet
                      ? {
                          width: '40px',
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                        }
                      : {
                          minWidth: '14px',
                          maxWidth: '14px',
                          marginRight: '8px',
                        }
                  }
                >
                  <Checkbox
                    checked={isCheckboxChecked(el)}
                    disabled={isCheckBoxDisabled(el)}
                    style={{ padding: 0, maxWidth: '14px' }}
                    className='checbox-style'
                  />
                </ListItemIcon>{' '}
                {el.title}
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      )}
    </div>
  );
}

function useOnClickOutside(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, cb]);
}
