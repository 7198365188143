import React from 'react';
import './index.css';
import FreshWaterLegend from './freshWaterLegend/FreshWaterLegend';
import SaltWaterLegend from './saltWaterLegend/SaltWaterLegend';
// import { planetbgiColors, planetbgiColorsNumbers, s3bgiColors, s3bgiColorsNumbers } from '../../shared/UI/constants/colorsPalette';
// import { SATELLITES } from '../Constants';

export default function LegendComponent({ selectedLocation, selectedLayers, satelliteView, legends }) {
	// const bgiColors = satelliteView === SATELLITES.copernicus ? s3bgiColors : planetbgiColors;
	// const colorNumbers = satelliteView === SATELLITES.copernicus ? s3bgiColorsNumbers : planetbgiColorsNumbers;

	// return (
	// 	<div className={isTablet ? 'legend-container tablet' : 'legend-container'}>
	// 		{bgiColors.map((el, index) => (
	// 			<div className="legend-row" key={index}>
	// 				<div className="color-block" style={el.style}></div>
	// 				<span className="color-title">{el.title}</span>
	// 			</div>
	// 		))}

	// 		<div className="legend-divider" />

	// 		{colorNumbers.map((el, index) => (
	// 			<div className="legend-row" key={index + el.title}>
	// 				<div className="color-block" style={el.style}></div>
	// 				<span className="color-title">{el.title}</span>
	// 			</div>
	// 		))}
	// 	</div>
	// );

	if (selectedLocation.saltWater) {
		//check which layers we show

		return <SaltWaterLegend selectedLayers={selectedLayers} />;
	} else {
		return <FreshWaterLegend satelliteView={satelliteView} legends={legends}  />;
	}
}
