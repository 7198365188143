import { Link } from '@mui/material';
import React from 'react';

export default function TreatmentInfo({ mainTitle, description, formulasToDisplay, contactButtonClicked, contactButtonText, reportTreatmentCB, reportTreatmentButtonText, lastTreatmentDate, lastTreatment }) {
	return (
		<div className="treatment-content display-flex display-flex-column">
			<span className="treatment-title"> {mainTitle} </span>
			{description ? <span className="treatment-description"> {description} </span> : ''}

			{description && formulasToDisplay
				? formulasToDisplay.map((el, index) => (
						<span className="treatment-description" key={index}>
							{el}
						</span>
				  ))
				: ''}

			<div className="display-flex display-flex-row" style={{ marginTop: '8px' }}>
				<Link underline="always" className="contact-link display-flex ">
					<span onClick={contactButtonClicked}>{contactButtonText}</span>
				</Link>

				<div style={{ background: '#D9D9D9', width: '1px', height: '10px', margin: '5px 10px' }}></div>

				<Link underline="always" className="contact-link display-flex ">
					<span onClick={reportTreatmentCB}>{reportTreatmentButtonText}</span>
				</Link>

				{lastTreatmentDate ? <div style={{ background: '#D9D9D9', width: '1px', height: '10px', margin: '5px 10px' }}></div> : ''}

				{lastTreatmentDate ? <span className="last-treat-text">{lastTreatment}</span> : ''}
			</div>
		</div>
	);
}
