import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import { Button, TextField } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MaterialSelect from '../../../../../shared/UI/components/materialSelect/MaterialSelect';
import { ACTIVITY_UPDATED_STATUS, APPLICATION_METHOD_DEFAULT_OPTIONS, Constants, FORMULAS_DEFAULT_OPTIONS, FORMULAS_OPTIONS_OTHER, TREATMENT, TREATMENT_MAX_TOTAL_AMOUNT, UPDATE_ACTIVITIES_URL } from '../../../../Constants';
import { fetch } from '../../../../Services';
import dayjs from 'dayjs';
import { findActivityChanges } from '../../../../../services/treatmentsServices';

export default function ReportTreatmentContent({ showReportTreatment, setShowReportTreatment, user, formulas, locationsId, activitiesList, setActivitiesList, selectedActivity, setSelectedActivity, setShowNotification }) {
	const title = 'Report a treatment';
	const locationTitle = 'Location';
	const startTimeTitle = 'Start Time';
	const endTimeTitle = 'End Time';
	const formulaTitle = 'Formula';
	const applicationMethodTitle = 'Application method';
	const dosageTitle = 'Dosage';
	const totalAmountTitle = 'Total amount';
	const notesTitle = 'Notes';
	const sendButtonText = selectedActivity ? 'Update' : 'Send';
	const cancelButtonText = 'Cancel';
	const messingFieldsMessage = '* All fields are required.';

	const [startTime, setStartDate] = useState(selectedActivity ? dayjs(selectedActivity.startTime) : '');
	const [endTime, setEndDate] = useState(selectedActivity ? dayjs(selectedActivity.endTime) : '');
	const [notes, setNote] = useState(selectedActivity ? selectedActivity.notes : '');
	const [dosage, setDosage] = useState(selectedActivity ? selectedActivity.metadata?.dosage : '');
	const [totalAmount, setTotalAmount] = useState(selectedActivity ? selectedActivity.metadata?.totalAmount : '');
	const [zone, setSelectedZone] = useState(selectedActivity ? selectedActivity.zone : '');
	const [formula, setSelectedFormula] = useState(selectedActivity ? selectedActivity.metadata?.formula : '');
	const [applicationMethod, setApplicationMethod] = useState(selectedActivity ? selectedActivity.metadata?.applicationMethod : '');
	const [showWrarning, setShowWarning] = useState(false);

	const scrollToForm = useRef(false);
	const formRef = useRef('');

	useEffect(() => {
		if (showReportTreatment && !scrollToForm?.current && selectedActivity) {
			scrollToForm.current = true;
			formRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}

		return () => {
			setSelectedActivity(undefined);
		};
	}, [showReportTreatment, selectedActivity]);

	let formulaOptions;

	if (formulas && formulas.length) {
		formulaOptions = formulas.map((el) => ({ title: el, value: el }));
		formulaOptions = [...formulaOptions, { title: 'Other', value: 'Other' }];
	} else {
		formulaOptions = FORMULAS_DEFAULT_OPTIONS;
	}

	const locationOptions = [
		{
			title: 'All',
			value: 'All',
		},
		{
			title: 'Region 1',
			value: 'Region 1',
		},
		{
			title: 'Region 2',
			value: 'Region 2',
		},
		{
			title: 'Region 3',
			value: 'Region 3',
		},
		{
			title: 'Region 4',
			value: 'Region 4',
		},
		{
			title: 'Region 5',
			value: 'Region 5',
		},
		{
			title: 'Region 6',
			value: 'Region 6',
		},
	];
	const applicationMethodOptions = APPLICATION_METHOD_DEFAULT_OPTIONS;

	const unit = Constants.UNITS[user.units].Prescription.label;
	const totalAmountUnits = Constants.UNITS[user.units].totalAmount.label;

	let startDatePickerProps = { className: 'background-white', onChange: (data) => setStartDate(data), maxDate: dayjs(new Date()) };
	let endDatePickerProps = { className: 'background-white', onChange: (data) => setEndDate(data), maxDate: dayjs(new Date()) };

	if (startTime) {
		endDatePickerProps['minDate'] = startTime;
		if (startTime) {
			startDatePickerProps['defaultValue'] = startTime;
		}
	}
	if (endTime) {
		startDatePickerProps['maxDate'] = endTime;
		if (endTime) {
			endDatePickerProps['defaultValue'] = endTime;
		}
	}

	const isDataValid = () => {
		if (!startTime || !endTime || !formula || !zone || !applicationMethod) {
			setShowWarning(true);
			return false;
		}
		const hasError = !(totalAmount && totalAmount > 0) || !(dosage && dosage > 0);
		if (hasError && formula !== FORMULAS_OPTIONS_OTHER) {
			// if formula is other -> allow zero's
			setShowWarning(true);
			return false;
		}
		return true;
	};

	const onSendClick = () => {
		//validate data

		if (!isDataValid()) {
			return '';
		}

		const activity = {
			type: TREATMENT,
			locationsId: locationsId,
			zone: zone,
			startTime: startTime['$d'],
			endTime: endTime['$d'],
			notes: notes,
			metadata: {
				formula: formula,
				dosage: dosage,
				totalAmount: totalAmount,
				applicationMethod: applicationMethod,
			},
		};
		const activitiesCopy = activitiesList ? activitiesList : [];
		//send data to api

		fetch({
			url: UPDATE_ACTIVITIES_URL,
			data: { activities: [activity] },
		})
			.then((response) => {
				if (response && response?.Success && response?.Success.length) {
					// if all is good hide the treatment form content
					setShowReportTreatment(!showReportTreatment);

					//update activities list
					setActivitiesList([{ ...activity, activitiesId: response.Success[0] }, ...activitiesCopy]);
					setShowNotification({
						show: true,
						msg: 'The treatment item has been successfully created',
						severity: 'success',
					});
				}
				if (response && response?.Error && response?.Error.length) {
					setShowNotification({
						show: true,
						msg: 'Failed to create the treatment item. Please refresh the page and try again later.',
						severity: 'error',
					});
				}
			})
			.catch((e) => {
				console.log('e', e);
				setShowNotification({
					show: true,
					msg: 'Failed to create the treatment item. Please refresh the page and try again later.',
					severity: 'error',
				});
			});
	};

	const onUpdateClick = () => {
		if (!isDataValid()) {
			return '';
		}
		let newActivity = {
			type: TREATMENT,
			activitiesId: selectedActivity.activitiesId,
			locationsId: locationsId,
			startTime: startTime['$d'],
			endTime: endTime['$d'],
			metadata: {
				applicationMethod,
				dosage,
				formula,
				totalAmount,
			},
			zone,
			notes,
		};
		//find changes between selectedActivity and new values
		const changes = findActivityChanges(selectedActivity, newActivity);
		const activityToSend = {
			activitiesId: selectedActivity.activitiesId,
			_id: selectedActivity._id,
			locationsId: locationsId,
			...changes,
			status: ACTIVITY_UPDATED_STATUS,
		};

		// api
		let activitiesCopy = activitiesList ? [...activitiesList] : [];

		fetch({
			url: UPDATE_ACTIVITIES_URL,
			data: { activities: [activityToSend] },
		})
			.then((response) => {
				if (response && response?.Success && response?.Success.length) {
					const index = activitiesCopy.findIndex((el) => el.activitiesId === activityToSend.activitiesId);
					if (index >= 0) {
						let newValue = { ...selectedActivity, ...changes };

						if (changes.metadata) {
							newValue.metadata = { ...selectedActivity.metadata, ...changes.metadata };
						}
						activitiesCopy[index] = { ...activitiesCopy[index], ...newValue }; // spread changes into the existing element
					}
					// if all is good hide the treatment form content
					setShowReportTreatment(!showReportTreatment);
					//once update is done
					setSelectedActivity(undefined);

					//update activities list
					setActivitiesList([...activitiesCopy]);

					// show Success Notification
					setShowNotification({
						show: true,
						msg: 'The treatment item has been successfully updated',
						severity: 'success',
					});
				}
				if (response && response?.Error && response?.Error.length) {
					setShowNotification({
						show: true,
						msg: 'Failed to update the treatment item. Please refresh the page and try again later.',
						severity: 'error',
					});
				}
			})
			.catch((e) => {
				console.log('e', e);
				setShowNotification({
					show: true,
					msg: 'Failed to update the treatment item. Please refresh the page and try again later.',
					severity: 'error',
				});
			});
	};

	const onActionButtonClick = () => {
		if (selectedActivity) {
			onUpdateClick();
		} else {
			onSendClick();
		}
	};

	const renderValue = (data) => {
		return <span> {data} </span>;
	};

	const cancelButtonClick = () => {
		setShowReportTreatment(false);
		setSelectedActivity(undefined);
	};

	return (
		<div className="content-container display-flex display-flex-column" ref={formRef}>
			<span className="content-title">{title}</span>

			<span className="title">{locationTitle}</span>

			<MaterialSelect
				selectStyle={{ height: '28px', borderRadius: '4px', background: 'white', padding: '4px 0px' }}
				value={zone}
				options={locationOptions}
				className={'location-select-container background-white'}
				handleChange={(data) => {
					setSelectedZone(data?.target?.value);
				}}
				renderValue={renderValue}
				isMenuDisabled={false}
				menuItemClassName={''}
			/>

			<div className="content-row display-flex-row display-flex">
				<div className="formula-container display-flex-column display-flex">
					<span className="content-text"> {startTimeTitle} </span>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DemoContainer components={['DateTimePicker']} sx={{ padding: 0, maxHeight: '33px' }}>
							<DateTimePicker {...startDatePickerProps} sx={{ height: '50px', maxHeight: '33px' }} className="treatment-date-picker" />
						</DemoContainer>
					</LocalizationProvider>{' '}
				</div>
				<div className="dosage-container display-flex-column display-flex m-l-16">
					<span className="content-text"> {endTimeTitle} </span>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DemoContainer components={['DateTimePicker']} sx={{ padding: 0, maxHeight: '33px' }}>
							<DateTimePicker {...endDatePickerProps} sx={{ height: '50px', maxHeight: '33px' }} className="treatment-date-picker" />
						</DemoContainer>
					</LocalizationProvider>
				</div>
			</div>

			<div className="content-row display-flex-row display-flex">
				<div className="formula-container display-flex-column display-flex">
					<span className="content-text"> {formulaTitle} </span>
					<MaterialSelect
						selectStyle={{ height: '29px', borderRadius: '4px', background: 'white', padding: '4px 0px' }}
						value={formula}
						options={formulaOptions}
						className={'treatment-select-container'}
						handleChange={(data) => {
							setSelectedFormula(data?.target?.value);
						}}
						renderValue={renderValue}
						isMenuDisabled={false}
						menuItemClassName={''}
					/>
				</div>

				<div className="formula-container display-flex-column display-flex m-l-16">
					<span className="content-text"> {applicationMethodTitle} </span>
					<MaterialSelect
						selectStyle={{ height: '29px', borderRadius: '4px', background: 'white', padding: '4px 0px' }}
						value={applicationMethod}
						options={applicationMethodOptions}
						className={'treatment-select-container'}
						handleChange={(data) => {
							setApplicationMethod(data?.target?.value);
						}}
						renderValue={renderValue}
						isMenuDisabled={false}
						menuItemClassName={''}
					/>
				</div>
			</div>

			<div className="content-row display-flex-row display-flex">
				<div className="dosage-container display-flex-column display-flex">
					<div className="display-flex-row display-flex">
						<span className="content-text"> {totalAmountTitle} </span>
						<span className="dosage-text-unit">{`(${totalAmountUnits})`}</span>
					</div>

					<TextField
						value={totalAmount}
						style={{ marginTop: '8px' }}
						onChange={(e) => {
							let value = e?.target?.value;
							if (value === '0') {
								value = parseInt(value);
							}
							if (value > 0 && value >= TREATMENT_MAX_TOTAL_AMOUNT) {
								value = value >= TREATMENT_MAX_TOTAL_AMOUNT ? TREATMENT_MAX_TOTAL_AMOUNT : value;
							} else {
								if (formula === FORMULAS_OPTIONS_OTHER) {
									value = value >= 0 ? value : 0;
								} else {
									value = value > 0 ? value : '';
								}
							}
							setTotalAmount(value);
						}}
						inputProps={{ step: '0.01', min: formula === FORMULAS_OPTIONS_OTHER ? '0' : '1' }}
						type="number"
						variant="outlined"
						className="background-white report-treatment-input-design"
						size="small"
					/>
				</div>

				<div className="dosage-container display-flex-column display-flex m-l-16">
					<div className="display-flex-row display-flex">
						<span className="content-text"> {dosageTitle} </span>
						<span className="dosage-text-unit">{`(${unit})`}</span>
					</div>

					<TextField
						value={dosage}
						style={{ marginTop: '8px' }}
						onChange={(e) => {
							let value = e.target.value;
							if (value === '0') {
								value = parseInt(value);
							}
							value = value > 0 ? value : 0;

							if (formula !== FORMULAS_OPTIONS_OTHER) {
								setDosage(value === 0 ? '' : value); //zero's not allowed
							} else {
								setDosage(value);
							}
						}}
						inputProps={{ step: '0.01', min: formula === FORMULAS_OPTIONS_OTHER ? '0' : '1' }}
						type="number"
						variant="outlined"
						className="background-white report-treatment-input-design"
						size="small"
					/>
				</div>
			</div>

			<span className="content-text" style={{ marginTop: '16px', marginBottom: '6px' }}>
				{notesTitle}
			</span>
			<TextField id="outlined-basic" value={notes} onChange={(e) => setNote(e.target.value)} variant="outlined" className="background-white notes-input style" />

			<div className="action-buttons display-flex display-flex-row">
				<span className="content-text requierd-data-text" style={{ color: 'red' }}>
					{showWrarning ? messingFieldsMessage : ''}
				</span>
				<div style={{ width: '70%' }}>
					<Button variant="text" onClick={() => cancelButtonClick()}>
						{cancelButtonText}
					</Button>
					<Button variant="contained" onClick={onActionButtonClick}>
						{sendButtonText}
					</Button>
				</div>
			</div>
		</div>
	);
}
