import React from 'react';
import './index.css';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Typography } from '@mui/material';
import { Constants } from '../../../Constants';
import { format, parse } from 'date-fns';
import GetWeatherIcon from './getWeatherIcon/GetWeatherIcon';
import { getWindDirection } from '../../../../services/trendsServices';
import WarningIcon from '@mui/icons-material/Warning';
export default function MainSection({ tempratureMin, showAverageRange, tempratureMax, windSpeed, bloomLevel, bloomSpread, selectedDate, user, bloomLevelAnnotation, wc, windDirection, precip, isTablet, location }) {
	const bloomLevelTitle = location?.saltWater ? 'Bloom Level (max)' : 'Bloom Level';
	const bloomSpreadTitle = 'Bloom Spread';
	const factor = Constants.UNITS[user.units].Temp.factor;

	const tempratureToDisplay = (tempratureMax * factor).toFixed(Constants.UNITS[user.units].Temp.decimals) || '--';
	const firstBlock = (
		<div className={isTablet ? 'first-block tablet' : 'first-block'}>
			{wc !== null ? <GetWeatherIcon wc={wc} isTablet={isTablet} /> : ''}

			{wc !== null ? (
				<div className="temprature-data">
					<span className="temp-text">
						<span className="temp-style">{tempratureToDisplay}</span> <span className={isTablet ? 'temp-icon tablet' : 'temp-icon'}> {Constants.UNITS[user.units].Temp.label} </span>
						<span className={isTablet ? 'temp-divider tablet' : 'temp-divider'} style={isTablet ? {} : { alignItems: 'center', height: '11px', width: '11px' }}>
							{showAverageRange ? '—' : ''}
						</span>
						<span className="temp-style">{showAverageRange ? (tempratureMin * factor).toFixed(Constants.UNITS[user.units].Temp.decimals) : ''}</span> <span className={isTablet ? 'temp-icon tablet' : 'temp-icon'}> {showAverageRange ? Constants.UNITS[user.units].Temp.label : ''} </span>
					</span>
					<span className="bottom-text">
						{/* <img src="/windIcon.png" alt="Wind speed" /> */}
						<div className="speed-wind">
							<span style={{ fontFamily: 'Inter', fontSize: '12px', fontStyle: 'normal', fontWeight: '300', lineHeight: 'normal' }}>{`Wind: ${windSpeed}${Constants.UNITS[user.units].Wind.label} (${getWindDirection(windDirection)}) Precip: ${precip}${
								Constants.UNITS[user.units].Precipitation.label
							}`}</span>
						</div>
					</span>
				</div>
			) : (
				<div style={{ alignItems: 'center', display: 'flex' }}>
					<WarningIcon />
					<span style={{ fontWeight: 'normal' }}>Weather data unavailable</span>
				</div>
			)}
		</div>
	);
	const secondBlock = (
		<div className="bloom-section">
			<span className="bloom-level-percentage">
				{bloomLevel} <span style={{ textTransform: 'lowercase' }}> {bloomLevelAnnotation} </span>{' '}
			</span>
			<span className="bloom-level-percentage" style={{ fontSize: '10px', opacity: '0.5', letterSpacing: '0', color: 'black', fontWeight: '300' }}>
				{bloomLevelTitle}
			</span>
		</div>
	);
	const lastBlock = (
		<div className="bloom-section">
			<span className="bloom-level-percentage">{`${bloomSpread}%`}</span>
			<span className="bloom-level-percentage" style={{ fontSize: '10px', opacity: '0.5', letterSpacing: '0', color: 'black', fontWeight: '300' }}>
				{bloomSpreadTitle}
			</span>
		</div>
	);
	let shortDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	shortDate = parse(shortDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date());
	let destinationDate = format(shortDate, Constants.DATE_FORMATS.LONG_DATE);

	return (
		<div style={{ borderTop: '1px solid rgba(0, 0, 0, 0.3)', height: '105px' }}>
			<Typography className="date-title">{destinationDate}</Typography>
			<div className="main-section">
				{firstBlock}

				<span className="main-divider" />

				{secondBlock}
				<span className="main-divider" />

				{lastBlock}
			</div>
		</div>
	);
}
