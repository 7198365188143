import React from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './index.css';
export default function ActivitiesMenu({ editActivity, disabled, deleteActivity }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const deleteActivityCb = () => {
		deleteActivity();
		handleClose();
	};
	const editActivityCb = () => {
		editActivity();
		handleClose();
	};
	const options = [
		{ title: 'Edit', cb: editActivityCb },
		{ title: 'Delete', cb: deleteActivityCb },
	];

	return (
		<div>
			<IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorEl}
				open={open}
				className="activity-actions-menu"
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={handleClose}>
				{options.map((option) => (
					<MenuItem key={option.title} selected={option === 'Pyxis'} onClick={option.cb} disabled={disabled}>
						{option.title}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
