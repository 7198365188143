import React from 'react';
import './index.css';
import { Link } from '@mui/material';
import ReportTreatmentContent from './reportTreatmentContent/ReportTreatmentContent';

export default function ReportTreatment({ user, formulas, locationsId, activitiesList, setActivitiesList, showReportTreatment, setShowReportTreatment, selectedActivity, setSelectedActivity, setShowNotification }) {
	const reportTreatmentLabel = '+ Report a treatment';

	return (
		<div className="report-treatment-container">
			<div className="report-treatment-button">
				<Link
					component="button"
					variant="body2"
					onClick={() => {
						setShowReportTreatment(!showReportTreatment);
					}}
					style={{ color: showReportTreatment ? 'rgba(0, 0, 0, 0.5)' : '' }}>
					{reportTreatmentLabel}
				</Link>
			</div>

			{showReportTreatment ? (
				<ReportTreatmentContent
					locationsId={locationsId}
					user={user}
					formulas={formulas}
					showReportTreatment={showReportTreatment}
					setShowReportTreatment={setShowReportTreatment}
					activitiesList={activitiesList}
					setActivitiesList={setActivitiesList}
					selectedActivity={selectedActivity}
					setSelectedActivity={setSelectedActivity}
					setShowNotification={setShowNotification}
				/>
			) : (
				''
			)}
		</div>
	);
}
