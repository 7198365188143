import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import BodySection from './components/bodySection/BodySection';
import BottomSection from './components/bottomSection/BottomSection';
import MainSection from './components/mainSection/MainSection';
import { MenuItem, Select } from '@mui/material';
import {
	ALERTS_ALL_ZONES_FILTER,
	ALERTS_READ_STATUS,
	ALERTS_TIME_DEFAULT_FILTER,
	ALERTS_UNREAD_STATUS,
	Constants,
	PULL_ALERTS_TIMER,
	TREATMENT_SEVERITY_STATUS_HIGH,
	TREATMENT_SEVERITY_STATUS_LOW,
	TREATMENT_SEVERITY_STATUS_MED,
	TREATMENT_SEVERITY_STATUS_NO_SEVERITY,
	TRENDS_ALERTS_TAB,
	TRENDS_ACTIVITIES_TAB,
	TRENDS_WATER_COMPONENTS_TAB,
	GET_ACTIVITIES_URL,
	TREATMENT_SEVERITY_STATUS_SEVERE,
	TREATMENT_SEVERITY_STATUS_NORMAL,
	DOSAGES_VALUES,
	UPDATE_ACTIVITIES_URL,
	DELETE_ACTIVITY_MODAL,
	ACTIVITY_UPDATED_DELETED,
} from '../Constants';
import { getBloomPredictedData, getValues } from '../../services/trendsServices';
import { differenceInCalendarYears, format, sub as subDays } from 'date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LakeInfo from './components/lakeInfo/LakeInfo';
import WaterComponents from './components/waterComponents/WaterComponents';
import dayjs from 'dayjs';
import { Tab, Tabs } from '@mui/material';
import AlertsList from './components/alertsList/AlertsList';
import AlertsFilters from './components/alertsFilters/AlertsFilters';
import { filterAlerts, getAlertsDataFromNotifications, getAlertsNumberColour, getTimeFilters, getZonesList, readAllAlertsService } from '../../services/alertsServices';
import { fetch } from '../Services';
import Treatment from './components/treatment/Treatment';
import { calculateAvgBpi, getPrescriptionBpi } from '../../services/treatmentsServices';
import Activities from './components/activities/Activities';
import ReportTreatment from './components/reportTreatment/ReportTreatment';

export default function MainTrends(props) {
	const trendsTitle = 'Water body trends';
	const alertsTitle = 'Alerts';
	const activitiesTitle = 'Activities';
	const defaultTrendType = 'BloomLevel';
	const defaultWeatherType = 'Temp';
	const { selectedDate, isTablet, location, user, collapseTrends, userMode, satelliteView, trendDataForAllSatellites, setSelectedDate, showLayersAfterDateChange, formulas, setShowNotification, setModalProps, setModalOpened } = props.data;
	const [alertsNumber, setAlertsNumber] = useState(0);
	const [alertsNumberColour, setAlertsNumberColour] = useState('transparent');
	const [selectedAlertDate, setSelectedAlertDate] = useState(undefined);

	let context = props.data;
	const isSaltWater = location?.saltWater || false;
	let trendData = [];
	let [dateRange, setDateRangeState] = useState(Constants.TREND_DATE_RANGES[1]); //past month default time frame.
	const [selectedTab, setSelectedTab] = useState(TRENDS_WATER_COMPONENTS_TAB);
	const [activitiesList, setActivitiesList] = useState();
	const [showTreatmentInGrapth, setShowTreatmentInGraph] = useState(false);

	const [alerts, setAlerts] = useState();
	const [showReportTreatment, setShowReportTreatment] = useState(false);

	const showAlerts = location && location.displayAlerts ? true : false;
	let [trendType, setTrendType] = useState(defaultTrendType);

	let [weatherType, setWeatherType] = useState(defaultWeatherType);

	const [dateRanges, setDateRangesState] = useState(Constants.TREND_DATE_RANGES);
	const [selectedActivity, setSelectedActivity] = useState();

	const [headerData, setHeaderData] = useState(null);
	const [alertsFilter, setAlertsFilter] = useState({ time: ALERTS_TIME_DEFAULT_FILTER, zone: ALERTS_ALL_ZONES_FILTER });

	let dosageOxy = '';
	let dosageBlu = '';
	let bpi = ''; //the will hold bpi value for last 15 days

	let severity = '';
	const [past4DaysBpi, setPast4DaysBpi] = useState(''); //the will hold bpi value with shift of 4 days
	useEffect(() => {
		let dateRangesCopy = [...Constants.TREND_DATE_RANGES];
		let helpArray = [...trendData];
		if (trendData && trendData.length && !past4DaysBpi) {
			helpArray.splice(helpArray.length - 4, 4); //remove last 4 elements

			const startDateShiftedBpi = subDays(subDays(new Date(), { days: 4 }), { days: 14 });
			const endDateShiftedBpi = subDays(new Date(), { days: 4 });

			const result = calculateAvgBpi(helpArray, startDateShiftedBpi, endDateShiftedBpi);

			if (dateRangesCopy.length === Constants.TREND_DATE_RANGES.length) {
				let additionalRanges = [];
				let thisYear = new Date().getFullYear();

				for (let yearIndex = 1; yearIndex <= differenceInCalendarYears(trendData[trendData.length - 1].date, trendData[0].date); yearIndex++) {
					additionalRanges.push({
						id: (thisYear - yearIndex).toString(),
						label: (thisYear - yearIndex).toString(),
						format: Constants.DATE_FORMATS.MONTH_NAME_FORMAT,
						legendFormat: Constants.DATE_FORMATS.MONTH_NAME_FORMAT,
						title: 'By Week',
						tag: 'Week of ',
					});
				}

				if (trendData[0].date.getFullYear() === trendData[trendData.length - 1].date.getFullYear()) {
					dateRangesCopy.splice(-1);
				}

				let newValue = additionalRanges.length > 0 ? [...dateRangesCopy, ...additionalRanges] : dateRangesCopy;
				if (dateRanges.length !== newValue.length) {
					setDateRangesState(newValue); // should be initialized on init only.
				}
			}
			setPast4DaysBpi(result);
		}
	});
	if (context.trendData) {
		trendData = trendDataForAllSatellites ? trendDataForAllSatellites[satelliteView] : [];

		const startDate = subDays(new Date(), { days: 14 });
		const endDate = new Date();
		bpi = calculateAvgBpi(trendData, startDate, endDate);
		if (bpi >= 0 && bpi <= 10) {
			severity = TREATMENT_SEVERITY_STATUS_NORMAL;
		} else if (bpi > 10 && bpi <= 50) {
			severity = TREATMENT_SEVERITY_STATUS_LOW;
			dosageOxy = DOSAGES_VALUES[user.units]['TREATMENT_SEVERITY_STATUS_LOW'].dosageOxy;
			dosageBlu = DOSAGES_VALUES[user.units]['TREATMENT_SEVERITY_STATUS_LOW'].dosageBlu;
		} else if (bpi > 50 && bpi <= 500) {
			severity = TREATMENT_SEVERITY_STATUS_MED;
			dosageOxy = DOSAGES_VALUES[user.units]['TREATMENT_SEVERITY_STATUS_MED'].dosageOxy;
			dosageBlu = DOSAGES_VALUES[user.units]['TREATMENT_SEVERITY_STATUS_MED'].dosageBlu;
		} else if (bpi > 500 && bpi <= 5000) {
			severity = TREATMENT_SEVERITY_STATUS_SEVERE;
			dosageOxy = DOSAGES_VALUES[user.units]['TREATMENT_SEVERITY_STATUS_SEVERE'].dosageOxy;
			dosageBlu = DOSAGES_VALUES[user.units]['TREATMENT_SEVERITY_STATUS_SEVERE'].dosageBlu;
		} else if (bpi > 50000) {
			severity = TREATMENT_SEVERITY_STATUS_HIGH;
			dosageOxy = DOSAGES_VALUES[user.units]['TREATMENT_SEVERITY_STATUS_HIGH'].dosageOxy;
			dosageBlu = DOSAGES_VALUES[user.units]['TREATMENT_SEVERITY_STATUS_HIGH'].dosageBlu;
		} else {
			severity = TREATMENT_SEVERITY_STATUS_NO_SEVERITY;
		}
		// severity = TREATMENT_SEVERITY_STATUS_NO_SEVERITY;//
	}
	const bloomPredictedData = getBloomPredictedData(trendData);

	let period, monthlyData;
	try {
		period = getValues([...trendData], dateRange, context, user);
		monthlyData = getValues(
			[...trendData],
			{
				id: 'Monthly',
				label: 'Monthly',
				format: Constants.DATE_FORMATS.MONTH_YEAR_FORMAT,
				legendFormat: Constants.DATE_FORMATS.SHORT_MONTH_YEAR_FORMAT,
				title: 'By Month',
				tag: 'Month of ',
			},
			context,
			user
		);
		monthlyData = monthlyData?.current;
	} catch (e) {
		// error with calculating data- should be messing data.
		console.log(e);
	}
	// let predictedPeriod = getValues([...trendData, ...bloomPredictedData], dateRange, context);

	const previousDate = useRef(null);
	useEffect(() => {
		let modifiedDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		modifiedDate = dayjs(modifiedDate);
		modifiedDate = modifiedDate['$d'];

		if (trendData && trendData.length > 0 && modifiedDate && previousDate.current !== modifiedDate) {
			let formattedDate = format(modifiedDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
			let index = trendData.findIndex((data) => {
				if (data && data.date) {
					let formatGivenDate = format(data.date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
					return formatGivenDate === formattedDate;
				} else {
					return undefined;
				}
			});

			previousDate.current = modifiedDate['$d'];
			if (index >= 0) {
				setHeaderData(trendData[index]); // header data should get its data from imagery[satelliteView].trendData
			}
		}

		if (!activitiesList) {
			// fetch activities
			fetch({
				url: GET_ACTIVITIES_URL,
				data: { locationsId: location.locationsId },
			}).then((response) => {
				if (response && response.length) {
					setActivitiesList(response);
				}
			});
		}
	}, [selectedDate, trendData, activitiesList, location.locationsId]);
	// let imagery;
	// let satelliteKey = getSatelliteParamName(satteLiteView)
	// if(images && Object.keys(images) && Object.keys(images).length > 0 ){
	// 	let formatSelectedDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	// 	imagery = images[formatSelectedDate].imagery;
	// 	if(imagery && imagery[satelliteKey] && imagery[satelliteKey].trendData ){
	// 		setHeaderData(imagery[satelliteKey].trendData);// header data should get its data from imagery[satelliteView].trendData
	// 	}
	// }

	useEffect(() => {
		//initial pull alerts request
		if (!alerts && showAlerts) {
			fetch({
				url: `getNotifications`,
				data: {},
			})
				.then(async (response) => {
					let alertsFromNotifications = getAlertsDataFromNotifications([...response], location);
					alertsFromNotifications.sort(compareDates);

					let number = 0;
					for (const alert of alertsFromNotifications) {
						if (alert.value.status === ALERTS_UNREAD_STATUS) {
							number++;
						}
					}
					setAlertsNumber(number);
					const colour = getAlertsNumberColour(alertsFromNotifications);
					setAlerts(alertsFromNotifications);

					setAlertsNumberColour(colour);
				})
				.catch((error) => {
					console.log({ error });
				});
		}
		const interval = setInterval(() => {
			if (showAlerts) {
				//fetch alerts

				let data = {};
				if (alerts && alerts.length > 0) {
					data = { lastNotificationsId: alerts[alerts.length - 1].value.notificationsId };
				}
				fetch({
					url: `getNotifications`,
					data: data,
				}).then(async (response) => {
					if (response?.length > 0) {
						const filteredResponse = getAlertsDataFromNotifications(response, location);
						const newAlerts = [...alerts, ...filteredResponse];
						let number = 0;
						for (const alert of newAlerts) {
							if (alert.value.status === ALERTS_UNREAD_STATUS) {
								number++;
							}
						}
						setAlerts(newAlerts);
						setAlertsNumber(number);
					}
				});
			}
		}, PULL_ALERTS_TIMER);
		return () => clearInterval(interval);
	}, [alerts, showAlerts, location]);

	function toFixed(x, key) {
		return Number.parseFloat(x).toFixed(Constants.UNITS[context.user.units][key].decimals);
	}

	const bloomSpreadAverage = period?.averages && period.averages['BloomSpread'];
	const bloomLevelAverage = period?.averages && period.averages['BloomLevel'];
	const bloomSpreadAnnotation = period?.averages && period.averages[`BloomSpreadAnnotation`];
	const bloomLevelAnnotation = period?.averages && period.averages[`BloomLevelAnnotation`];

	const carbonAverage = period?.averages && period.averages['Carbon'];
	const carbonAnnotation = period?.averages[`CarbonAnnotation`];

	let bloomLevelDelta = period?.averages && period.averages[`BloomLevelDelta`];
	let bloomLevelDirection = '';
	let bloomSpreadDelta = period?.averages && period.averages[`BloomSpreadDelta`];
	let bloomSpreadDirection = '';

	let carbonDelta = period?.averages && period.averages[`CarbonDelta`];
	let carbonDirection = '';

	const weatherValues = headerData ? headerData.weather[headerData.weatherKeys[0]] : '';
	let tempratureMin = null;
	let tempratureMax = null;

	if (headerData && weatherValues && weatherValues.temperature_2m_min) {
		tempratureMin = weatherValues.temperature_2m_min;
	}
	if (headerData && weatherValues && weatherValues.temperature_2m_max) {
		tempratureMax = weatherValues.temperature_2m_max;
	}

	const showAverageRange = tempratureMin !== undefined && tempratureMin !== null && tempratureMax !== undefined && tempratureMax !== null;

	const mainSectionProps = {
		windSpeed: headerData ? weatherValues?.windspeed_10m : '',
		bloomLevel: headerData ? toFixed(headerData?.BloomLevel, 'BloomLevel') : '',
		bloomSpread: headerData ? toFixed(headerData?.BloomSpread, 'BloomSpread') : '',
		selectedDate: selectedDate,
		user: context.user,
		bloomLevelAnnotation: bloomLevelAnnotation,
		wc: headerData && weatherValues ? weatherValues?.weathercode : null,
		windDirection: headerData && weatherValues ? headerData && weatherValues?.winddirection_10m : '',
		precip: headerData && weatherValues ? headerData && weatherValues?.precipitation : '',
		isTablet: isTablet,
		showAverageRange,
		location: location,
	};

	if (bloomLevelDelta && bloomLevelDelta.includes(Constants.UP)) {
		bloomLevelDelta = bloomLevelDelta.replace(Constants.UP, '');
		bloomLevelDirection = 'up';
	}
	if (bloomLevelDelta && bloomLevelDelta.includes(Constants.DOWN)) {
		bloomLevelDelta = bloomLevelDelta.replace(Constants.DOWN, '');
		bloomLevelDirection = 'down';
	}
	if (bloomSpreadDelta && bloomSpreadDelta.includes(Constants.UP)) {
		bloomSpreadDelta = bloomSpreadDelta.replace(Constants.UP, '');
		bloomSpreadDirection = 'up';
	}
	if (bloomSpreadDelta && bloomSpreadDelta.includes(Constants.DOWN)) {
		bloomSpreadDelta = bloomSpreadDelta.replace(Constants.DOWN, '');
		bloomSpreadDirection = 'down';
	}

	if (carbonDelta && carbonDelta.includes(Constants.UP)) {
		carbonDelta = carbonDelta.replace(Constants.UP, '');
		carbonDirection = 'up';
	}
	if (carbonDelta && carbonDelta.includes(Constants.DOWN)) {
		carbonDelta = carbonDelta.replace(Constants.DOWN, '');
		carbonDirection = 'down';
	}

	const bodyProps = {
		bloomSpreadAverage,
		bloomLevelAverage,
		carbonAverage,
		bloomSpreadAnnotation,
		bloomLevelAnnotation,
		carbonAnnotation,
		bloomLevelDelta,
		bloomSpreadDelta,
		bloomLevelDirection,
		bloomSpreadDirection,
		carbonDelta,
		carbonDirection,
		setTrendType,
		setDateRangeState,
		user: user,
		location: location,
		dateRange,
	};

	const bottomProps = {
		dateRange,
		current: period?.current,
		trendType: trendType,
		period,
		weatherType,
		context,
		setWeatherType,
		trendData: trendData,
		dateRanges: dateRanges,
		isTablet: isTablet,
		bloomPredictedData: bloomPredictedData,
		location: location,
		activitiesList: activitiesList,
		selectedAlertDate,
		user: user,
		setSelectedAlertDate: (data) => {
			if (data) {
				setSelectedAlertDate(data);
				setSelectedTab(TRENDS_ACTIVITIES_TAB);
			}
		},
		showTreatmentInGrapth,
		setShowTreatmentInGraph,
		monthlyData: monthlyData,
	};

	const setDateRange = (event) => {
		let value = dateRanges.find((_dateRange) => _dateRange.id === event.target.value);
		setDateRangeState(value);
	};

	const lakeInfoProps = {
		location: location,
		user: user,
	};

	let mainContainerClassName = 'trends-container';
	if (isTablet) {
		mainContainerClassName = mainContainerClassName + ' tablet';
	}
	if (collapseTrends) {
		mainContainerClassName = mainContainerClassName + ' collapsed';
	}

	const waterComponentsProps = {
		period: period,
		dateRange: dateRange,
		satelliteView: satelliteView,
	};

	const getTrendsTabContent = () => {
		return (
			<>
				{/* {!showAlerts ? (
					<Typography className="trends-title" style={{ color: '#000000', opacity: '0.75', letterSpacing: '-0.28px' }}>
						{trendsTitle}
					</Typography>
				) : (
					''
				)} */}

				<div style={{ padding: '9px 8px 13px 16px', height: '41px', display: 'flex' }}>
					<Select value={dateRange.id} className="select-period" onChange={setDateRange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
						{dateRanges.map((_dateRange) => (
							<MenuItem key={'c' + Math.floor(Math.random() * 99999999999) + _dateRange.id} value={_dateRange.id}>
								<AccessTimeIcon style={{ height: '16px', width: '16px' }} fontSize="small" /> <span style={{ marginLeft: '6px' }}> {_dateRange.label}</span>
							</MenuItem>
						))}
					</Select>
				</div>

				{<BodySection {...bodyProps} />}
				{period ? <BottomSection {...bottomProps} /> : ''}
				{isTablet ? '' : userMode === 'expert' && !isSaltWater && period ? <WaterComponents {...waterComponentsProps} /> : ''}
				<hr style={{ margin: '0' }} />

				<LakeInfo {...lakeInfoProps} />
			</>
		);
	};

	const readAlerts = async (unreadAlerts) => {
		const isUnreadAlert = unreadAlerts && unreadAlerts[0] && unreadAlerts[0].status === ALERTS_UNREAD_STATUS ? true : false;
		const selectedAlert = await readAllAlertsService(alertsNumber, alerts, setAlerts, unreadAlerts);
		if (selectedAlert && selectedAlert.value && selectedAlert.value.metadata && selectedAlert.value.timestamp) {
			const date = new Date(selectedAlert.value.timestamp);

			if (isUnreadAlert && selectedAlert.value.status === ALERTS_READ_STATUS) {
				let newAlertNumber = alertsNumber - 1;
				newAlertNumber = newAlertNumber > 0 ? newAlertNumber : 0;
				setAlertsNumber(newAlertNumber);
			}

			let modifiedDate = format(date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
			const index = location.satellites[satelliteView].dates.findIndex((el) => el === modifiedDate);
			if (index < 0) {
				return '';
			}
			modifiedDate = dayjs(modifiedDate);
			await setSelectedDate(modifiedDate);
			return showLayersAfterDateChange(modifiedDate['$d']);
		} else {
			return '';
		}
	};

	const compareDates = (a, b) => {
		let aDate = new Date(a.value.timestamp);
		let bDate = new Date(b.value.timestamp);
		return bDate - aDate;
	};
	const getAlertsTabContent = () => {
		const zones = getZonesList(alerts);
		const timeFilters = getTimeFilters();
		let filteredAlerts = alerts;
		filteredAlerts.sort(compareDates);
		filteredAlerts = filterAlerts(filteredAlerts, alertsFilter);

		return (
			<>
				{' '}
				<AlertsFilters timeFilters={timeFilters} alertsFilter={alertsFilter} zones={zones} setAlertsFilter={setAlertsFilter} />
				<AlertsList readAlerts={readAlerts} alerts={filteredAlerts} />{' '}
			</>
		);
	};
	const getActivitiesTabContent = () => {
		const editActivity = (activity) => {
			if (activity) {
				if (!showReportTreatment) {
					setShowReportTreatment(true);
				}
				setSelectedActivity(activity);
			}
		};

		const deleteActivity = (activity) => {
			const deleteAction = () => {
				if (activity) {
					let newActivity = { ...activity };

					//update status to deleted
					newActivity.status = ACTIVITY_UPDATED_DELETED;
					//api to delete activity
					fetch({
						url: UPDATE_ACTIVITIES_URL,
						data: { activities: [newActivity] },
					})
						.then((response) => {
							if (response && response?.Success && response?.Success.length) {
								let activitiesCopy = [...activitiesList];

								//update activities list
								activitiesCopy = activitiesCopy.filter((el) => el.activitiesId !== newActivity.activitiesId); //filter deleted activity.
								setActivitiesList([...activitiesCopy]);
								setShowNotification({
									show: true,
									msg: 'The treatment item has been successfully deleted',
									severity: 'success',
								});
							}

							if (response && response?.Error && response?.Error.length) {
								setShowNotification({
									show: true,
									msg: 'Failed to delete the treatment item. Please refresh the page and try again later.',
									severity: 'error',
								});
							}
						})
						.catch((e) => {
							console.log('e', e);
							setShowNotification({
								show: true,
								msg: 'Failed to delete the treatment item. Please refresh the page and try again later.',
								severity: 'error',
							});
						});
				}
			};
			setModalOpened(true);
			setModalProps({
				open: true,
				closeModal: async () => {
					await setModalOpened(false);
				},
				modalTitle: 'Delete treatment?',
				type: DELETE_ACTIVITY_MODAL,
				actionButtonClick: deleteAction,
				actionButtonTitle: 'Delete treatment',
				closeActionButtonTitle: 'Cancel',
				setModalOpened: setModalOpened,
				location: location,
				errorMessage: 'Are you sure you want to delet this treatment? this action canot be restored',
			});
		};

		return (
			<>
				<ReportTreatment
					showReportTreatment={showReportTreatment}
					setShowReportTreatment={setShowReportTreatment}
					user={user}
					formulas={formulas}
					locationsId={location.locationsId}
					activitiesList={activitiesList}
					setActivitiesList={setActivitiesList}
					selectedActivity={selectedActivity}
					setSelectedActivity={setSelectedActivity}
					setShowNotification={setShowNotification}
				/>
				<Activities deleteActivity={deleteActivity} activitiesList={activitiesList} user={user} selectedAlertDate={selectedAlertDate} editActivity={editActivity} userMode={userMode} />;
			</>
		);
	};

	const renderTabContent = () => {
		if (selectedTab === TRENDS_WATER_COMPONENTS_TAB) {
			return getTrendsTabContent();
		} else if (selectedTab === TRENDS_ALERTS_TAB) {
			return getAlertsTabContent();
		} else if (selectedTab === TRENDS_ACTIVITIES_TAB) {
			return getActivitiesTabContent();
		}
	};

	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};
	const alertsLabelComponent = (
		<span style={{ display: 'flex' }}>
			<span style={{ marginRight: '5px' }} className={`tabs-text ${selectedTab === TRENDS_WATER_COMPONENTS_TAB ? '' : 'selected'}`}>
				{alertsTitle}{' '}
			</span>
			{alertsNumber ? <span className="alerts-number" style={{ background: alertsNumberColour }}>{`${alertsNumber}`}</span> : ''}
		</span>
	);

	const activitiesLabelComponent = (
		<span style={{ display: 'flex' }}>
			<span style={{ marginRight: '5px' }} className={`tabs-text ${selectedTab === TRENDS_WATER_COMPONENTS_TAB ? '' : 'selected'}`}>
				{activitiesTitle}{' '}
			</span>
		</span>
	);

	const trendLabelTitle = (
		<span style={{ display: 'flex' }}>
			<span className={`tabs-text ${selectedTab === TRENDS_ALERTS_TAB ? '' : 'selected'}`}> {trendsTitle} </span>
		</span>
	);

	let lastTreatmentDate = '';
	if (activitiesList && activitiesList.length) {
		lastTreatmentDate = activitiesList[0].startTime;
		lastTreatmentDate = new Date(lastTreatmentDate).toLocaleDateString();
	}

	return (
		<div className={mainContainerClassName}>
			<MainSection {...mainSectionProps} tempratureMin={tempratureMin} tempratureMax={showAverageRange ? tempratureMax : weatherValues?.temperature_2m || 0} />
			<Treatment
				user={user}
				dosageOxy={dosageOxy}
				dosageBlu={dosageBlu}
				severity={severity}
				lastTreatmentDate={lastTreatmentDate}
				locationsId={location.locationsId}
				showReportTreatment={showReportTreatment}
				setShowReportTreatment={setShowReportTreatment}
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
				formulas={formulas}
				bpi={bpi}
				past4DaysBpi={past4DaysBpi}
			/>

			<div style={{ padding: '14px 0px', boxSizing: 'content-box', marginLeft: '10px' }}>
				<Tabs value={selectedTab} onChange={handleChange} className="tabs-container">
					<Tab value={TRENDS_WATER_COMPONENTS_TAB} label={trendLabelTitle} className="tab-button" />
					<Tab value={TRENDS_ACTIVITIES_TAB} label={activitiesLabelComponent} wrapped className="tab-button" />
					{showAlerts ? <Tab value={TRENDS_ALERTS_TAB} label={alertsLabelComponent} wrapped className="tab-button" /> : ''}
				</Tabs>
			</div>

			{renderTabContent()}
		</div>
	);
}
