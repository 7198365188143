import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Constants, DELETE_ACTIVITY_MODAL, ERROR_MODAL, EXPORT_MODAL } from '../Constants';
import './index.css';

import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DateBlock from '../../shared/UI/components/dateBlock/DateBlock';
import { endOfMonth, isBefore, format } from 'date-fns';

export default function MainModal({ open, closeModal, modalTitle, type, actionButtonClick, startDateTitle, endDateTitle, actionButtonTitle, setModalOpened, closeActionButtonTitle, location, errorMessage }) {
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 300,
		height: 242,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	return (
		<Modal open={open} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
			{type && GetModalContent({ type, style, modalTitle, actionButtonClick, startDateTitle, endDateTitle, actionButtonTitle, setModalOpened, closeActionButtonTitle, closeModal, location, errorMessage })}
		</Modal>
	);
}

function GetModalContent({ type, style, modalTitle, startDateTitle, endDateTitle, actionButtonClick, actionButtonTitle, setModalOpened, closeActionButtonTitle, closeModal, location, errorMessage }) {
	const [from, setFrom] = useState(dayjs('2016-01-01'));
	const [exportClicked, setExportClicked] = useState({ flag: false, msg: '' });

	let minDate = dayjs(format(location.dateRange[0].start_date, Constants.DATE_FORMATS.DATE_FORMAT));
	let maxDate = isBefore(new Date(), location.dateRange[0].end_date) ? endOfMonth(new Date()) : location.dateRange[0].end_date;

	const [to, setTo] = useState(dayjs(format(maxDate, Constants.DATE_FORMATS.DATE_FORMAT)));

	const buttonClick = async () => {
		if (exportClicked.msg) {
			//we have message -> close modal
			await closeModal();
			return;
		}
		if (!from || !to || !from['$d'] || !to['$d']) return;

		let response = await actionButtonClick(from['$d'], to['$d']);
		if (response.isSuccess) {
			//success case
			setExportClicked({ flag: true, msg: response.msg });
		} else {
			//error case
			setExportClicked({ flag: true, msg: response.msg });
		}
	};

	if (type === EXPORT_MODAL) {
		const classes = {
			titleClass: 'from-date-block',
			dateClass: 'date-picker-wrapper',
			datePickerTitle: 'date-picker-title',
			datePickerClass: 'date-picker',
		};
		const dateProps = {
			...classes,
			title: startDateTitle,
			datePickerProps: {
				value: from,
				minDate: minDate,
				onChange: (newValue) => {
					setFrom(newValue);
				},
				className: 'date-picker',
				maxDate: to,
			},
		};

		const toDateProps = {
			...classes,
			title: endDateTitle,
			datePickerProps: {
				value: to,
				onChange: (newValue) => setTo(newValue),
				className: 'date-picker',
				maxDate: dayjs(format(location.dateRange[0].end_date, Constants.DATE_FORMATS.DATE_FORMAT)),
				minDate: from,
			},
		};

		return (
			<Box sx={style} className="export-modal">
				<Typography className="modal-title" variant="h6" style={{ color: '#000000', opacity: '0.75', letterSpacing: '-0.32px' }}>
					{modalTitle}
				</Typography>

				{!exportClicked.flag && (
					<IconButton aria-label="" onClick={() => setModalOpened(false)} edge="end" style={{ position: 'absolute', right: '11.5px', top: '8px' }}>
						<CloseIcon style={{ width: '16px', height: '16px' }} fontSize="small" />
					</IconButton>
				)}

				{!exportClicked.flag && <DateBlock {...dateProps} />}
				{!exportClicked.flag && <DateBlock {...toDateProps} />}

				{exportClicked.flag && (
					<Box className="confirmation-container">
						<Typography variant="subtitle2" gutterBottom className="confirmation-message">
							{exportClicked.msg}
						</Typography>
					</Box>
				)}

				<Button variant="contained" onClick={buttonClick} className="submit-button">
					{exportClicked.flag ? closeActionButtonTitle : actionButtonTitle}
				</Button>
			</Box>
		);
	} else if (type === ERROR_MODAL) {
		return (
			<Box sx={style} className="export-modal">
				<Typography className="modal-title" variant="h6" style={{ color: '#000000', opacity: '0.75', letterSpacing: '-0.32px' }}>
					{modalTitle}
				</Typography>

				<Box className="confirmation-container">
					<Typography variant="subtitle2" gutterBottom className="confirmation-message">
						{errorMessage}
					</Typography>
				</Box>

				<Button variant="contained" onClick={closeModal} className="submit-button">
					{closeActionButtonTitle}
				</Button>
			</Box>
		);
	} else if (type === DELETE_ACTIVITY_MODAL) {
		let styleCopy = { ...style };
		styleCopy.width = 420;
		styleCopy.height = 167;
		return (
			<Box sx={styleCopy} className="export-modal">
				<Typography className="modal-title" variant="h6" style={{ color: '#000000', letterSpacing: '-0.32px' }}>
					{modalTitle}
				</Typography>

				<Box className="confirmation-container" style={{ width: '100%' }}>
					<Typography variant="subtitle2" gutterBottom className="confirmation-message" style={{ opacity: '0.75' }}>
						{errorMessage}
					</Typography>
				</Box>

				<Box className={'delete-activity-action-buttons'}>
					<Button variant="text" onClick={closeModal} className="delete-activity-button" style={{ marginRight: '10px', paddingBottom: '0', color: 'rgba(0,0,0,0.50)' }} color="inherit">
						{closeActionButtonTitle}
					</Button>
					<Button
						variant="contained"
						onClick={() => {
							actionButtonClick();
							closeModal();
						}}
						className="delete-activity-button">
						{actionButtonTitle}
					</Button>
				</Box>
			</Box>
		);
	}

	return <></>;
}
