// export const getPrescriptionBpi = (trendData, calculatedDataNumber) => {
// 	// loop over given number = calculatedDataNumber with  valid elements and calculate averge bloom level
// 	let i = trendData?.length - 1,
// 		count = 0,
// 		bloomCounts=0;
// 	let maxBloom = 0;
// 	let avgBloom = 0;
// 	let element;
// 	let startDate = '';
// 	let currentDate = '';
// 	let differenceInDays = '';
// 	const millisecondsPerDay = 1000 * 60 * 60 * 24;
// 	const calculationPeriod = 14;

import dayjs from 'dayjs';

// 	if (i >= 0) {
// 		startDate = new Date(trendData[i]?.date);
// 	}

// 	const maxCloudCoverage = 50;
// 	if (trendData && trendData.length && trendData.length >= calculationPeriod) {
// 		for (i; count < calculatedDataNumber && trendData[i]; i--) {
// 			count++;
// 			element = trendData[i];
// 			currentDate = new Date(element.date);
// 			differenceInDays = startDate?.getTime() - currentDate?.getTime();
// 			differenceInDays = differenceInDays / millisecondsPerDay; //convert milliseconds time to days

// 			if (element && element.BloomLevel && element.BloomLevel >= 0 && element.Clouds < maxCloudCoverage && differenceInDays < calculationPeriod) {
// 				maxBloom = maxBloom > element.BloomLevel ? maxBloom : element.BloomLevel;
// 				avgBloom += element.BloomLevel;

// 			}
// 		}
// 		maxBloom = maxBloom.toFixed(2);
// 	} else {
// 		return -1;
// 	}
// 	return maxBloom;
// };

export const calculateAvgBpi = (trendData, startDate, endDate) => {
	const maxCloudCoverage = 50;

	let element = '';
	let bloomAverage = 0;
	let bloomAveragesCount = 0;
	for (let i = trendData?.length - 1; i >= 0; i--) {
		element = trendData[i];
		if (new Date(element.date) > endDate) {
			continue;
		}
		if (new Date(element.date) < startDate) {
			break;
		}
		if (element.BloomLevel >= 0 && element.Clouds < maxCloudCoverage) {
			bloomAverage += element.BloomLevel;
			bloomAveragesCount++;
		}
	}
	bloomAveragesCount = bloomAveragesCount === 0 ? 1 : bloomAveragesCount;

	return bloomAverage / bloomAveragesCount;
};

export const getPrescriptionBpi = (trendData, calculatedDataNumber) => {
	// loop over given number = calculatedDataNumber with  valid elements and calculate averge bloom level
	let i = trendData?.length - 1,
		count = 0,
		bloomCount = 0;
	let maxBloom = 0;
	let avgBloom = 0;
	let element;
	let startDate = '';
	let currentDate = '';
	let differenceInDays = '';
	const millisecondsPerDay = 1000 * 60 * 60 * 24;
	const calculationPeriod = 14;

	if (i >= 0) {
		startDate = new Date(trendData[i]?.date);
	}

	const maxCloudCoverage = 50;
	if (trendData && trendData.length && trendData.length >= calculationPeriod) {
		for (i; count < calculatedDataNumber && trendData[i]; i--) {
			count++;
			element = trendData[i];
			currentDate = new Date(element.date);
			differenceInDays = startDate?.getTime() - currentDate?.getTime();
			differenceInDays = differenceInDays / millisecondsPerDay; //convert milliseconds time to days

			if (element && element.BloomLevel && element.BloomLevel >= 0 && element.Clouds < maxCloudCoverage && differenceInDays < calculationPeriod) {
				avgBloom += element.BloomLevel;
				bloomCount++;
			}
		}

		avgBloom = avgBloom / bloomCount;
	} else {
		return -1;
	}
	return avgBloom;
};

export const getFormulasToDisplay = (formulas, dosages) => {
	// if (!formulas || !dosages || !dosages[0]) {
	// 	return [];
	// }
	let result = [],
		index = 0;

	formulas.forEach((el) => {
		if (index !== formulas.length - 1) {
			result.push(`${el}, or`);
		} else {
			result.push(`${el} .`);
		}
		index++;
	});
	return result;
};

export const findActivityChanges = (oldActivity, newActivity) => {
	if (!oldActivity || !newActivity) return undefined;
	let changes = {};
	let hasChanges = false;

	const startTime = dayjs(oldActivity.startTime);
	if (startTime['$d'].getTime() !== newActivity.startTime.getTime() || (!startTime['$d'] && newActivity.startTime)) {
		changes['startTime'] = newActivity.startTime;
		hasChanges = true;
	}

	const endTime = dayjs(oldActivity.endTime);
	if (endTime['$d'].getTime() !== newActivity.endTime.getTime() || (!endTime['$d'] && newActivity.endTime)) {
		changes['endTime'] = newActivity.endTime;
		hasChanges = true;
	}

	if (oldActivity.notes !== newActivity.notes || (!oldActivity.notes && newActivity.notes)) {
		// check because it is not required field
		changes['notes'] = newActivity.notes;
		hasChanges = true;
	}
	if (oldActivity.zone !== newActivity.zone) {
		changes['zone'] = newActivity.zone;
		hasChanges = true;
	}

	if (oldActivity.metadata && newActivity.metadata) {
		changes.metadata = {};
		if (oldActivity.metadata.applicationMethod !== newActivity.metadata.applicationMethod) {
			changes.metadata['applicationMethod'] = newActivity.metadata.applicationMethod;
			hasChanges = true;
		}
		if (oldActivity.metadata.dosage !== newActivity.metadata.dosage) {
			changes.metadata['dosage'] = newActivity.metadata.dosage;
			hasChanges = true;
		}
		if (oldActivity.metadata.formula !== newActivity.metadata.formula) {
			changes.metadata['formula'] = newActivity.metadata.formula;
			hasChanges = true;
		}
		if (oldActivity.metadata.totalAmount !== newActivity.metadata.totalAmount) {
			changes.metadata['totalAmount'] = newActivity.metadata.totalAmount;
			hasChanges = true;
		}
	}

	if (hasChanges) {
		if (Object.keys(changes.metadata).length === 0) {
			delete changes.metadata;
		}
		return changes;
	}
	return undefined;
};
